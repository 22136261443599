'use strict'

import mString from './string'

const LABELLEVIE_FRONT_BASE_URL = process.env.VUE_APP_LABELLEVIE_FRONT_BASE_URL

const _ingredientUnits = [
  { value: 'ml', text: 'ml' },
  { value: 'g', text: 'g' },
  { value: 'unit', text: 'unité' }
]

const _recipeDifficulties = {
  0: 'Trés facile',
  10: 'Facile',
  20: 'Élaboré'
}

const _formatRecipePublicUrl = (recipe) => {
  return `${LABELLEVIE_FRONT_BASE_URL}/#/r/${recipe.id}/${mString.slugify(recipe.name)}`
}

const _formatRecipePublicPdfUrl = (recipe) => {
  return `${LABELLEVIE_FRONT_BASE_URL}/pdf/recipe/${recipe.id}-${mString.slugify(recipe.name)}.pdf`
}

const _formatAdminRecipeUrlById = (recipeId) => {
  return `${LABELLEVIE_FRONT_BASE_URL}/recettes/recettes/${recipeId}`
}

const _getRecipeIdFromRecipeUrl = (recipeUrl) => {
  if (recipeUrl) {
    const _match = recipeUrl.match(/pdf\/recipe\/(\d+)-(.+)/)
    if (_match) return _match[1]
  }
  return ''
}

export default {
  ingredientUnits: () => _ingredientUnits,
  getIngredientUnit: (unit) => _ingredientUnits.find(o => o.value === unit) || { value: unit, text: '?' },
  difficulties: () => _recipeDifficulties,
  getDifficulty: (difficulty) => _recipeDifficulties[difficulty] || _recipeDifficulties[0],
  formatAdminRecipeUrlById: _formatAdminRecipeUrlById,
  getRecipeIdFromRecipeUrl: _getRecipeIdFromRecipeUrl,
  formatRecipePublicUrl: _formatRecipePublicUrl,
  formatRecipePublicPdfUrl: _formatRecipePublicPdfUrl
}
