'use strict'

import { ApiDeleev } from '../index'

const _formatCenters = (centers) => {
  centers = centers.filter(o => o.status > 0)
  let _indexed = {}
  centers.forEach((o, idx) => {
    o.is_hidden = o.status <= 10
    _indexed[o.id] = idx
  })
  let sortedCenters = centers.slice().filter(o => !o.is_hidden)
  // default first, then superior status, then lexicographic on name
  sortedCenters.sort((a, b) => {
    return (+b.is_default - +a.is_default) || (b.status - a.status) || (b.is_active - a.is_active) || (a.id - b.id) // || a.name.localeCompare(b.name)
  })
  return {
    centers: centers,
    centers_indexed: _indexed,
    sortedCenters: sortedCenters
  }
}
export default {
  getCenters () {
    return new Promise((resolve, reject) => {
      ApiDeleev.get('/centers/', { params: { limit: 50 } })
        .then(response => {
          if (response.status >= 400) return reject(response)
          resolve(_formatCenters(response.data.results))
        })
        .catch(reject)
    })
  },

  getCheckCenters () {
    return new Promise((resolve, reject) => {
      ApiDeleev.get('/centers/check/')
        .then(response => {
          if (response.status >= 400) return reject(response)
          resolve(response.data)
        })
        .catch(reject)
    })
  }
}
