'use strict'

const _formatAdminMaxQuantityProductGroupUrl = () => {
  return `${process.env.VUE_APP_DELEEV_ADMIN_URL}/admin/suppliers/maxquantityproductgroup/`
}

const _formatAdminCategoriesUrl = (domain) => {
  return `${process.env.VUE_APP_DELEEV_ADMIN_URL}/ingredienttypestree/list_v2/?domain=${domain}`
}

export default {
  formatAdminMaxQuantityProductGroupUrl: _formatAdminMaxQuantityProductGroupUrl,
  formatAdminCategoriesUrl: _formatAdminCategoriesUrl
}
