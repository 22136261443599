'use strict'
import { ApiProxysearch } from '..'

const CANTEENS_INDEX_NAME = process.env.VUE_APP_CANTEENS_INDEX_NAME

export default {
  _search (params) {
    return new Promise((resolve, reject) => {
      ApiProxysearch.post('/staff/', params)
        .then(response => {
          if (response.status >= 400) return reject(response)
          resolve(response.data)
        })
        .catch(reject)
    })
  },
  searchCanteens (params) {
    let _params = {
      indexName: CANTEENS_INDEX_NAME,
      perPage: this.limit || 20,
      q: params.query || '*'
    }

    let _filters = []

    if (params.ids) {
      _filters.push(`id:[${[...params.ids]}]`)
    }

    if (_filters.length) {
      _params.filterBy = _filters.join(' && ')
    }

    return this._search(_params)
  }
}
