'use strict'

import axios from 'axios'
import ApiDeleevUtils from './deleev/utils'
import { ApiDeleev } from './index'

const _uploadFile = function (file, s3Data, url) {
  let postData = new FormData()
  for (let key in s3Data.fields) {
    postData.append(key, s3Data.fields[key])
  }
  postData.append('file', file)

  const requestURL = new URL(url)
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: requestURL.origin,
      data: postData,
      options: {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      }
    })
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(function (error) { error.errors = ['Erreur de téléversement du fichier']; reject(error) })
  })
}

const _registerFile = function (registerURL, file, urlToFile, objectType, objectId) {
  return new Promise((resolve, reject) => {
    ApiDeleev.post(registerURL,
      {
        url_to_file: urlToFile,
        file_name: file.name,
        file_content_type: file.type,
        object_type: objectType,
        object_id: objectId
      }
    )
      .then(response => {
        if (response.status >= 400) reject(response)
        else {
          response.data.urlToFile = urlToFile
          resolve(response.data)
        }
      })
      .catch(reject)
  })
}

const _uploadToS3AndRegister = async function (
  inputFile,
  registerURL,
  objectType,
  objectId
) {
  try {
    let data = await ApiDeleevUtils.signS3(inputFile, objectType, objectId)
    await _uploadFile(inputFile, data.data, data.url)
    return _registerFile(registerURL, inputFile, data.url, objectType, objectId)
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  uploadToS3AndRegister: _uploadToS3AndRegister
}
