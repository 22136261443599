'use strict'

import store from '@/store'
import mString from './string'

const _adminUrl = process.env.VUE_APP_DELEEV_ADMIN_URL
const _flowUrl = process.env.VUE_APP_DELEEV_FLOW_URL
const _teamUrl = process.env.VUE_APP_TEAM_ADMIN_URL

const _formatUserProfilLink = (useId) => {
  return `${_adminUrl}/users/${useId}/`
}

const _formatSupportLink = () => {
  return `${_flowUrl}/support`
}

const _formatUserTeamLink = (useId) => {
  return `${_teamUrl}/staffs/${useId}`
}

const _formatUserSupportLink = (useId, email) => {
  return `${_flowUrl}/support/user/${useId}/${email}`
}

const _formatUserOrderLink = (orderId) => {
  return `${_flowUrl}/support/order/${orderId}`
}

const _getFullName = (user, friendly) => {
  let _lastName = (user.last_name || '').toUpperCase()
  let _firstName = mString.capitalizeFirsts(user.first_name || '')
  let _fullname = friendly ? `${_firstName} ${_lastName}` : `${_lastName} ${_firstName}`
  return _fullname.trim() || user.name || user.email
}

const _multiCentersManagementAllowed = () => {
  return store.userMultiCentersManagementAllowed()
}

export default {
  formatUserProfilLink: _formatUserProfilLink,
  formatSupportLink: _formatSupportLink,
  formatUserTeamLink: _formatUserTeamLink,
  formatUserSupportLink: _formatUserSupportLink,
  formatUserOrderLink: _formatUserOrderLink,
  getFullName: _getFullName,
  multiCentersManagementAllowed: _multiCentersManagementAllowed
}
