'use strict'

import { ApiDeleev } from '../index'

const _signS3 = function (file, objectType, objectId) {
  return new Promise((resolve, reject) => {
    ApiDeleev.post('/utils/sign_s3/',
      {
        file_name: file.name,
        file_type: file.type,
        object_type: objectType,
        object_id: objectId
      }
    )
      .then(response => {
        if (response.status >= 400) reject(response)
        else resolve(response.data)
      })
      .catch(reject)
  })
}

export default {
  signS3: _signS3
}
