'use strict'

// async chunk: group-suppliers
const VueSuppliers = () => import(/* webpackChunkName: "group-suppliers" */ '../views/Suppliers/Suppliers.vue')
const VueSupplier = () => import(/* webpackChunkName: "group-suppliers" */ '../views/Suppliers/Supplier.vue')
const VueSupplierModal = () => import(/* webpackChunkName: "group-suppliers" */ '../views/Suppliers/SupplierModal.vue')
// async chunk for supplier_orders: group-supplier-order
const VueSupplierOrders = () => import(/* webpackChunkName: "group-supplier-order" */ '../views/Suppliers/Orders.vue')
const VueSupplierOrdersArrival = () => import(/* webpackChunkName: "group-supplier-order" */ '../views/Suppliers/OrdersArrival.vue')
const VueSupplierOrdersLastArrivals = () => import(/* webpackChunkName: "group-supplier-order" */ '../views/Suppliers/OrdersLastArrivals.vue')
const VueSupplierOrderForm = () => import(/* webpackChunkName: "group-supplier-order" */ '../views/Suppliers/OrderForm.vue')
const VueSupplierOrderFormModal = () => import(/* webpackChunkName: "group-supplier-order" */ '../views/Suppliers/OrderFormModal.vue')
const VueSupplierOrderFormGenerate = () => import(/* webpackChunkName: "group-supplier-order" */ '../views/Suppliers/OrderFormGenerate.vue')

export default [
  {
    path: '/suppliers',
    name: 'suppliers',
    component: VueSuppliers,
    meta: { permission: 'admin' }
  },
  {
    path: '/suppliers/:id',
    name: 'supplier',
    component: VueSupplierModal,
    beforeEnter: (to, from, next) => {
      if ([
        'suppliers',
        'supplier-orders',
        'products',
        'supplier-orders-arrival',
        'supplier-orders-last-arrivals',
        'products-qiqd',
        'products-qiqd-rules'
      ].indexOf(from.name) !== -1) {
        to.matched[0].components.default = from.matched[0].components.default
        to.matched[0].components.modal = VueSupplierModal
      } else {
        to.matched[0].components.default = VueSupplier
        to.matched[0].components.modal = false
      }
      next()
    },
    meta: { permission: 'admin' }
  },
  {
    path: '/supplier-orders',
    name: 'supplier-orders',
    component: VueSupplierOrders,
    meta: { permission: 'MeS' }
  },
  {
    path: '/supplier-orders/form/:id',
    name: 'supplier-order-form',
    component: VueSupplierOrderFormModal,
    beforeEnter: (to, from, next) => {
      if (['supplier-orders', 'supplier-orders-arrival'].indexOf(from.name) !== -1) {
        to.matched[0].components.default = from.matched[0].components.default
        to.matched[0].components.modal = VueSupplierOrderFormModal
      } else {
        to.matched[0].components.default = VueSupplierOrderForm
        to.matched[0].components.modal = false
      }
      next()
    },
    meta: { permission: 'admin' }
  },
  {
    path: '/supplier-orders/arrival/:id',
    name: 'supplier-orders-arrival',
    component: VueSupplierOrdersArrival,
    meta: { permission: 'MeS' }
  },
  {
    path: '/supplier-orders/last-arrivals',
    name: 'supplier-orders-last-arrivals',
    component: VueSupplierOrdersLastArrivals,
    meta: { permission: 'MeS' }
  },
  {
    path: '/supplier-orders/form/create-for-supplier/:id',
    name: 'supplier-order-form-create',
    component: VueSupplierOrderFormGenerate,
    meta: { permission: 'admin' }
  }
]
