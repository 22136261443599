'use strict'

import store from '@/store'

const _orderStatus = [
  { value: 'aside', text: 'Mise de côté' },
  { value: 'draft', text: 'Brouillon' },
  { value: 'sent', text: 'Envoyée' },
  { value: 'received', text: 'Reçue' },
  { value: 'blocked', text: 'Bloquée' },
  { value: 'done', text: 'Terminée' },
  { value: 'canceled', text: 'Annulée' },
  { value: 'manual', text: 'Manuelle' },
  { value: 'failed', text: 'Echec envoi' }
]

const _isSupplierOrderWillBeDoneStatus = (status) => (['sent', 'received'].indexOf(status) !== -1)

const _orderAdministrativeStatus = [
  { value: 0, text: 'A traiter' },
  { value: 1, text: 'Vérif. BL' },
  { value: 2, text: 'Vérif. facture' }
]

const _formatSupplierOrderArrivalUrl = (id) => {
  return `${process.env.VUE_APP_DELEEV_ADMIN_URL}/inventory/arrivals/table/order_form/${id}/`
}

const _formatSupplierAdministrativeFormUrl = (id) => {
  return `${process.env.VUE_APP_DELEEV_ADMIN_URL}/inventory/arrivals/administrative-detail/${id}/`
}

const _formatSupplierClaimUrl = (id) => {
  return `${process.env.VUE_APP_DELEEV_ADMIN_URL}/suppliers/order_claim/${id}`
}

const _formatSupplierClaimsUrl = () => {
  return `${process.env.VUE_APP_DELEEV_ADMIN_URL}/suppliers/order_claim`
}

const _formatSupplierOrderArrivalCreateUrl = (id) => {
  return `${process.env.VUE_APP_DELEEV_ADMIN_URL}/inventory/arrivals/create/?supplier_id=${id}`
}

const _formatSupplierNextOrderAutoProductsUrl = (supplierId, centerId) => {
  return `${process.env.VUE_APP_DELEEV_ADMIN_URL}/suppliers/auto_products/${supplierId}?logistics_center_id=${centerId || 1}`
}

const _formatDispatchSupplierOrderUrl = (orderIds) => {
  return `/pdf/products/supplier-orders/dispatch-order-${orderIds[0]}.pdf?supplier_order_ids=${orderIds.join(',')}`
}

const _formatLogScanUrl = (orderId) => {
  return `/products-logs-scan?order_form_id=${orderId}`
}

const _getLabelForBuyingUnit = (buyingType, buyingMethod, quantity = null) => {
  if (buyingType === 'pack') return 'colis'
  if (buyingMethod === 'kilo') return 'kilo'
  if (quantity === null || quantity > 1) return 'pièces'
  return 'pièce'
}

const _regexRule = /(lundi|mardi|mercredi|jeudi|vendredi|samedi|dimanche) (\d{1,2})h \+(\d{1,2})j(?: mes \+(\d{1,2})j (\d{1,2})h(?: (\d{1,2})% mes \+(\d{1,2})j (\d{1,2})h (\d{1,2})%)?)?/i
const _isValidRoutine = (routine) => {
  if (!routine) return true
  return !routine.split(',').find(rule => !_isValidRule(rule))
}
const _isValidRule = (rule) => {
  if (!rule) return false
  return _regexRule.exec(rule)
}
export default {
  isValidRule: _isValidRule,
  isValidRoutine: _isValidRoutine,
  orderStatusList: () => _orderStatus,
  orderStatus: (status) => _orderStatus.find(o => o.value === status) || { value: status, text: '?' },
  isSupplierOrderWillBeDoneStatus: _isSupplierOrderWillBeDoneStatus,
  orderAdministrativeStatusList: () => _orderAdministrativeStatus,
  orderAdministrativeStatus: (status) => _orderAdministrativeStatus.find(o => o.value === status) || { value: status, text: '?' },
  getSupplier: (supplierId) => store.getSupplier(supplierId) || { id: supplierId, name: `? - #${supplierId}` },
  getCategory: (category) => store.getSupplierCategorie(category),
  getOrderTemplate: (templateId) => store.getSupplierOrderTemplate(templateId),
  formatSupplierOrderArrivalUrl: _formatSupplierOrderArrivalUrl,
  formatSupplierAdministrativeFormUrl: _formatSupplierAdministrativeFormUrl,
  formatSupplierClaimUrl: _formatSupplierClaimUrl,
  formatSupplierClaimsUrl: _formatSupplierClaimsUrl,
  formatSupplierOrderArrivalCreateUrl: _formatSupplierOrderArrivalCreateUrl,
  formatSupplierNextOrderAutoProductsUrl: _formatSupplierNextOrderAutoProductsUrl,
  formatDispatchSupplierOrderUrl: _formatDispatchSupplierOrderUrl,
  getLabelForBuyingUnit: _getLabelForBuyingUnit,
  formatLogScanUrl: _formatLogScanUrl
}
