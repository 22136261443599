'use strict'

const _downloadImage = (link, filename) => {
  const _init = { method: 'GET', mode: 'cors', cache: 'default' }
  fetch(link, _init)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = filename || link.split('/').pop()
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
}

export default {
  downloadImage: _downloadImage
}
