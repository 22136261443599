'use strict'

const _orderStatus = [
  { value: 'paid', text: 'PAYÉE' },
  { value: 'prep', text: 'EN PRÉP.' },
  { value: 'prepared', text: 'FINALISÉE' },
  { value: 'shipping', text: 'EN LIVRAISON' },
  { value: 'received', text: 'LIVRÉE' },
  { value: 'undeliveredclient', text: 'PASLÀ' },
  { value: 'now', text: 'PREP. MNT.' },
  { value: 'later', text: 'PREP. + TARD' },
  { value: 'important', text: 'IMPORTANT' },
  { value: 'to-check', text: 'À VÉRIF.' },
  { value: 'waiting-products', text: 'ATTENTE PROD.' },
  { value: 'xd-to-check', text: 'CROSSDOCKING À VÉRIF.' },
  { value: 'stand-by', text: 'STAND BY' },
  { value: 'unpaid', text: 'À PAYER' },
  { value: 'redestock', text: 'DESTOCK (RE)' },
  { value: 'canceled', text: 'ANNULÉE' },
  { value: 'restock', text: 'RESTOCK' },
  { value: 'blocked', text: 'BLOQUÉE' }
]

const _formatOrderAdminUrl = (id) => {
  return `${process.env.VUE_APP_DELEEV_FLOW_URL}/support/order/${id}`
}

export default {
  formatOrderAdminUrl: _formatOrderAdminUrl,
  orderStatus: () => _orderStatus,
  getOrderStatus: (status) => _orderStatus.find(o => o.value === status) || { value: status, text: status }
}
