'use strict'

// async chunk: group-search-groups
const VueSearchGroups = () => import(/* webpackChunkName: "group-search-groups" */ '../views/Search/Groups.vue')
const VueSearchIngredients = () => import(/* webpackChunkName: "group-search-groups" */ '../views/Search/Ingredients.vue')

export default [
  {
    path: '/search-groups',
    name: 'search-groups',
    component: VueSearchGroups,
    meta: { permission: 'admin' }
  },
  {
    path: '/search-ingredients/:id?',
    name: 'search-ingredients',
    component: VueSearchIngredients,
    meta: { permission: 'admin' }
  }
]
