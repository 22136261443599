<template>
  <footer class="d-print-none text-center pt-4 pb-2">
    <div class="small">
      <b-link class="text-primary px-2" href="https://admin.deleev.com" target="_blank">admin.deleev.com</b-link>
      <b-link class="text-primary px-2" href="https://admin.labellevie.com" target="_blank">admin.labellevie.com</b-link>
      <b-link class="text-primary px-2" href="https://www.labellevie.com" target="_blank">labellevie.com</b-link>
    </div>
    <div class="smaller text-muted mt-1">@ deleev 2020-2023</div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
