'use strict'

import Vue from 'vue'
import VueCookies from 'vue-cookies'
import eventEmitter from './event-emitter'
import mObjects from './mixins/objects'
import mString from './mixins/string'
import { ApiDeleevConfig } from './api/index'
import ApiDeleevCenters from './api/deleev/centers'
import ApiDeleevSites from './api/deleev/sites'
import ApiDeleevUsers from './api/deleev/users'
import ApiDeleevProducts from './api/deleev/products'
import ApiDeleevCategories from './api/deleev/categories'
import ApiDeleevSuppliers from './api/deleev/suppliers'
import ApiDeleevSupplierOrders from './api/deleev/supplier_orders'
import ApiDeleevAreas from './api/deleev/areas'
import ApiDeleevProductTypology from './api/deleev/product_typology'
import ApiDeleevProductCategory from './api/deleev/product_frichti_categories'
import ApiDeleevProductFrichtiTypology from './api/deleev/product_frichti_typologies'
import ApiLabellevieProducts from './api/labellevie/products'
import ApiLabellevieDotations from './api/labellevie/dotation'
import ApiLabellevieLabo from './api/labellevie/labo'
import ApiLabellevieConfigs from './api/labellevie/configs'
import ApiSearchCanteen from './api/search/canteens'

Vue.use(VueCookies)
Vue.$cookies.config('30d', '/', process.env.NODE_ENV === 'production' ? '.deleev.com' : '')

// 2477 - aurelie.lazzarotto@deleev.com - LAZZAROTTO Aurelie
// 85583 - fanny.monteiller@deleev.com / FANNY Monteiller
// 105606 - samy.kouza@deleev.com - KOUZA Samy
// 81421 - ivrycuisine@deleev.com / IVRY Cuisine
// 232739 - romainluisa@hotmail.fr / LEFEBVRE Romain
// 33703 - vianney.copitet@gmail.com / COPITET Vianney
// 137353 - thomas.tregaro@deleev.com / TREGARO Thomas
// 50519 - genipha.bansimba@deleev.com / BANSIMBA Genipha
// 453986 - florence.luthi@frichti.co / LUTHI Florence
// 569686 - bastien.jacquesson@gmail.com / JACQUESSON Bastien
// 158768 - roubaif0@gmail.com / ROUBAI CHORFI Fethi
// 274460 - noufoun.coulibaly@deleev.com / COULIBALY Noufoun
// 53284 - gharis.azwaw@deleev.com / GHARIS Azwaw

const _superUsers = [2477, 105606, 85583, 137353, 50519, 453986, 569686, 158768, 274460, 53284]
const _laboUsers = [85583, 81421, 232739, 33703]

export default {
  state: {
    authStatus: '',
    token: '',
    user: {},

    sitesFetched: 'tofetch',
    sites: [],
    sites_indexed: {},
    sortedSites: [],
    default_site: null,

    centersFetched: 'tofetch',
    centers: [],
    centers_indexed: {},
    sortedCenters: [],
    default_center: null,

    categoriesFetched: 'tofetch',
    categories: {},

    suppliersFetch: 'tofetch',
    suppliers: [],
    suppliers_formatted: [],
    suppliers_indexed: {},
    suppliers_categories: [],
    suppliers_order_templates: [],

    productUnitsFetched: 'tofetch',
    units: [],
    units_indexed: {},

    productGroupsFetched: 'tofetch',
    groups: [],
    groups_formatted: [],
    groups_indexed: {},

    productBrandsFetched: 'tofetch',
    brands: [],
    brands_formatted: [],
    brands_indexed: {},

    productIndustrialsFetched: 'tofetch',
    industrials: [],
    industrials_formatted: [],
    industrials_indexed: {},

    areaClassificationsFetched: 'tofetch',
    areaClassifications: [],

    groupsAreasFetched: 'tofetch',
    groupsAreas: {},
    groupsAreas_indexed: {},

    maxQuantityProductGroupsFetched: 'tofetch',
    maxQuantityGroups: [],
    maxQuantityGroups_indexed: {},

    superuFamillesFetched: 'tofetch',
    superuFamilles: [],

    equipmentFetched: 'tofetch',
    equipment: [],
    equipment_indexed: {},

    productCornersFetched: 'tofetch',
    corners: [],
    corners_indexed: {},

    lbvConfigsStatus: {},
    lbvConfigs: {},

    // Categories coming from Frichti
    productCategoryFetched: 'tofetch',
    product_categories: [],
    product_categories_indexed: {},

    // Typologies coming from Frichti
    productFrichtiTypologyFetched: 'tofetch',
    product_frichti_typolygies: [],
    product_frichti_typolygies_indexed: {},

    // Canteens
    canteensIndexed: {}
  },
  getStoredToken () {
    let _token = Vue.$cookies.get('api_token')
    if (_token && _token !== 'undefined') return _token

    // _token = localStorage.getItem('api_token')
    // if (_token && _token !== 'undefined') return _token

    return ''
  },
  destroyToken () {
    this.state.token = ''
    this.state.user = {}
    // localStorage.removeItem('api_token')
    Vue.$cookies.remove('api_token')
    ApiDeleevConfig.removeAuthorizationToken()
  },
  async checkToken () {
    const token = this.getStoredToken()
    let _status = 'inprogress'
    try {
      const data = await ApiDeleevUsers.checkToken(token)
      if (data && data.is_staff) {
        this.setUser(data, token)
        _status = ''
      } else {
        _status = 'error'
      }
    } catch (e) {
      _status = 'error'
    }
    this.state.authStatus = _status
    eventEmitter.emit('auth:ckecked')
  },
  async login (user) {
    let _status = 'inprogress'
    this.state.authStatus = _status
    try {
      const loginData = await ApiDeleevUsers.login(user)
      if (loginData && loginData.auth_token) {
        const userData = await ApiDeleevUsers.checkToken(loginData.auth_token)
        if (userData && userData.is_staff) {
          this.setUser(userData, loginData.auth_token)
          eventEmitter.emit('auth:success')
          // localStorage.setItem('api_token', loginData.auth_token)
          Vue.$cookies.set('api_token', loginData.auth_token, '30d')
          _status = ''
        }
      } else {
        _status = 'error'
      }
    } catch (e) {
      _status = 'error'
    }
    this.state.authStatus = _status
    eventEmitter.emit('auth:updated')
  },
  async logout () {
    await ApiDeleevUsers.logout()
    this.destroyToken()
    eventEmitter.emit('auth:updated')
  },
  isLogged () {
    return this.state.token && this.state.user.is_staff
  },
  setUser (user, token) {
    this.state.token = token
    this.state.user = user

    ApiDeleevConfig.setAuthorizationToken(token)
  },
  getUser () {
    return this.state.user
  },
  getUserFavouriteCenter () {
    return this.state.user && this.state.user.staff_info.favourite_center ? this.state.user.staff_info.favourite_center : null
  },
  getUserCurrentCenter () {
    return this.state.user && this.state.user.staff_info.current_center ? this.state.user.staff_info.current_center : null
  },
  userMultiCentersManagementAllowed () {
    return this.getUserFavouriteCenter() === 9
  },
  userIsSuperAdmin () {
    const userGroup = this.state.user.groups || {}
    return userGroup.is_super_admin || _superUsers.indexOf(this.state.user.id) !== -1
  },
  userIsAdmin () {
    const userGroup = this.state.user.groups || {}
    return userGroup.is_super_admin || userGroup.is_acl_manager
  },
  userIsSupport () {
    const userGroup = this.state.user.groups || {}
    return userGroup.is_support
  },
  userIsPrep () {
    const userGroup = this.state.user.groups || {}
    return userGroup.is_prep
  },
  userCan (permission) {
    const _userIsSuperAdmin = this.userIsSuperAdmin()
    const _userIsAdmin = this.userIsAdmin()
    const _userIsPrep = this.userIsPrep()
    const _userIsSupport = this.userIsSupport()
    const _userMultiCentersManagementAllowed = this.userMultiCentersManagementAllowed()
    switch (permission) {
      case 'super-admin':
        return _userIsSuperAdmin
      case 'admin':
        return _userIsAdmin
      case 'admin-multi-centers':
        return _userIsAdmin && _userMultiCentersManagementAllowed
      case 'support':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        return _userIsSupport
      case 'category-manager':
        return _userIsSuperAdmin || this.state.user.staff_info.staff_type === 7
      case 'labo-manager':
        return _userIsSuperAdmin || _laboUsers.indexOf(this.state.user.id) !== -1
      case 'rezonage':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        return _userIsPrep && this.state.user.user_permissions.indexOf(621) !== -1 // 621  rezonage
      case 'inventaire':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        return _userIsPrep && this.state.user.user_permissions.indexOf(1094) !== -1 // 1094 inventaire
      case 'MeS':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        return _userIsPrep && this.state.user.user_permissions.indexOf(1089) !== -1 // 1089 MeS
      case 'MeS RF':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        return _userIsPrep && this.state.user.user_permissions.indexOf(1504) !== -1 // 1504 MeS RF
      case 'Zone':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        return _userIsPrep && this.state.user.user_permissions.some(p => [1612, 1613, 1614].includes(p))
      case 'DLC':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        // 1101 dlc tour - 1102 dlc sensible - 1103 dlc cuisine
        return _userIsPrep && this.state.user.user_permissions.filter(pId => [1101, 1102, 1103].indexOf(pId) !== -1).length > 0
      case 'change order form status': // can put aside 1640
        if (_userIsSuperAdmin || _userIsAdmin) return true
        return _userIsPrep && this.state.user.user_permissions.indexOf(1648) !== -1 // 1648 can put aside
      case 'change order form administrative status':
        if (_userIsSuperAdmin || _userIsAdmin) return true
        return this.state.user.user_permissions.indexOf(1830) !== -1
      default:
        return false
    }
  },

  async fetchSites () {
    if (['fetching', 'done'].indexOf(this.state.sitesFetched) !== -1) return

    try {
      this.state.sitesFetched = 'fetching'
      const data = await ApiDeleevSites.getSites()
      if (data.sites) this.state.sites = data.sites
      if (data.sites_indexed) this.state.sites_indexed = data.sites_indexed
      if (data.sortedSites) this.state.sortedSites = data.sortedSites
      this.state.default_site = this.state.sites.find(o => o.is_default) || this.state.sites[0]
      this.state.sitesFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('sites:fetched')
  },
  getSites () {
    return this.state.sites
  },
  getSortedSites () {
    return this.state.sortedSites
  },
  getSite (siteId) {
    if (typeof this.state.sites_indexed[siteId] !== 'undefined') {
      return this.state.sites[this.state.sites_indexed[siteId]]
    }
    return null
  },
  getDefaultSite () {
    return this.state.default_site
  },

  async fetchCenters () {
    if (['fetching', 'done'].indexOf(this.state.centersFetched) !== -1) return

    try {
      this.state.centersFetched = 'fetching'
      const data = await ApiDeleevCenters.getCenters()
      if (data.centers) this.state.centers = data.centers
      if (data.centers_indexed) this.state.centers_indexed = data.centers_indexed
      if (data.sortedCenters) this.state.sortedCenters = data.sortedCenters
      this.state.default_center = this.state.centers.find(o => o.is_default) || this.state.centers[0]
      this.state.centersFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('centers:fetched')
  },
  getCenters () {
    return this.state.centers
  },
  getSortedCenters () {
    return this.state.sortedCenters
  },
  getCenter (centerId) {
    if (typeof this.state.centers_indexed[centerId] !== 'undefined') {
      return this.state.centers[this.state.centers_indexed[centerId]]
    }
    return { id: centerId, name: '?' }
  },
  isHiddenCenter (centerId) {
    return this.getCenter(centerId).is_hidden
  },
  getDefaultCenter () {
    return this.state.default_center
  },

  async fetchCategories (siteIds) {
    if (['fetching', 'done'].indexOf(this.state.categoriesFetched) !== -1) return

    try {
      this.state.categoriesFetched = 'fetching'
      siteIds = siteIds || this.state.sites.map(s => s.id)
      let response
      for (const siteId of siteIds) {
        response = await ApiDeleevCategories.getCategories(siteId)
        this.state.categories[siteId] = { data: [], formatted: [], treeselect: [], indexed: {} }
        if (response.categories) this.state.categories[siteId].data = response.categories
        if (response.categories_formatted) this.state.categories[siteId].formatted = response.categories_formatted
        if (response.categories_treeselect) this.state.categories[siteId].treeselect = response.categories_treeselect
        if (response.categories_indexed) this.state.categories[siteId].indexed = response.categories_indexed
      }

      this.state.categoriesFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('categories:fetched')
  },
  async reFetchCategories (siteIds) {
    this.state.categoriesFetched = 'tofetch'
    await this.fetchCategories(siteIds)
  },
  getCategories (variant, siteId, hasMultipleDescedents = false) {
    siteId = siteId || 1
    let categories = this.state.categories[siteId][variant || 'data']
    if (variant === 'indexed') {
      return categories
    }
    categories = categories.filter(o => !o.is_deleted)
    if (hasMultipleDescedents && variant === 'formatted') {
      return categories.filter(category => category.descendents > 2)
    }
    return categories
  },
  getCategory (categoryId, siteId) {
    siteId = siteId || 1
    if (this.state.categories[siteId] === undefined) {
      return null
    }
    return typeof this.state.categories[siteId].indexed[categoryId] !== 'undefined'
      ? this.state.categories[siteId].formatted[this.state.categories[siteId].indexed[categoryId]]
      : null
  },

  async fetchSuppliers () {
    if (['fetching', 'done'].indexOf(this.state.suppliersFetch) !== -1) return

    try {
      this.state.suppliersFetch = 'fetching'

      const categories = await ApiDeleevSuppliers.getSupplierCategories()
      if (categories) {
        this.state.suppliers_categories = categories.map(o => {
          return { value: o.id, text: o.label }
        })
      }

      const templates = await ApiDeleevSupplierOrders.getSupplierOrderTemplates()
      if (templates) {
        this.state.suppliers_order_templates = templates.supplierOrderTemplates
      }

      const data = await ApiDeleevSuppliers.getSuppliers()
      if (data.suppliers) this.state.suppliers = data.suppliers
      if (data.suppliers_formatted) this.state.suppliers_formatted = data.suppliers_formatted
      if (data.suppliers_indexed) this.state.suppliers_indexed = data.suppliers_indexed
      this.state.suppliersFetch = 'done'
    } catch (e) {}

    eventEmitter.emit('suppliers:fetched')
  },
  getSuppliers (variant) {
    return this.state[variant ? `suppliers_${variant}` : 'suppliers']
  },
  getSupplierCategories () {
    return this.state.suppliers_categories
  },
  getSupplierCategorie (category) {
    return this.state.suppliers_categories.find(o => o.value === category) || { value: category, text: '?' }
  },
  getSupplierOrderTemplates () {
    return this.state.suppliers_order_templates
  },
  getSupplierOrderTemplate (templateId) {
    return this.state.suppliers_order_templates.find(o => o.id === templateId) || { name: templateId, text: '?' }
  },
  getSupplier (supplierId) {
    if (this.state.suppliers_indexed[supplierId] !== 'undefined') {
      return this.state.suppliers[this.state.suppliers_indexed[supplierId]]
    }
    return null
  },
  updateSupplier (supplier) {
    let _model = this.state.suppliers.find(o => o.id === supplier.id)
    if (_model) {
      Object.assign(_model, supplier)
    }

    let _formatted = this.state.suppliers_formatted.find(o => o.id === supplier.id)
    if (_formatted) {
      Object.assign(_formatted, mObjects.pick(supplier, ['id', 'name', 'is_internal']))
      _formatted.search = mString.slugify(_formatted.name)
    }
  },

  async fetchProductUnits () {
    if (['fetching', 'done'].indexOf(this.state.productUnitsFetched) !== -1) return

    try {
      this.state.productUnitsFetched = 'fetching'
      const data = await ApiDeleevProducts.getUnits()
      if (data.units) this.state.units = data.units
      if (data.units_indexed) this.state.units_indexed = data.units_indexed
      this.state.productUnitsFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('product-units:fetched')
  },
  getProductUnits (variant) {
    return this.state[variant ? `units_${variant}` : 'units']
  },
  getProductUnit (unitId) {
    return this.state.units_indexed[unitId]
  },

  async fetchProductBrands () {
    if (['fetching', 'done'].indexOf(this.state.productBrandsFetched) !== -1) return

    try {
      this.state.productBrandsFetched = 'fetching'
      const data = await ApiDeleevProducts.getBrands()
      if (data.brands) this.state.brands = data.brands
      if (data.brands_formatted) this.state.brands_formatted = data.brands_formatted
      if (data.brands_indexed) this.state.brands_indexed = data.brands_indexed
      this.state.productBrandsFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('product-brands:fetched')
  },
  getProductBrands (variant) {
    return this.state[variant ? `brands_${variant}` : 'brands']
  },
  getProductBrand (brandId) {
    if (typeof this.state.brands_indexed[brandId] !== 'undefined') {
      return this.state.brands_formatted[this.state.brands_indexed[brandId]]
    }
    return null
  },
  getProductBrandByName (brandName) {
    return this.state.brands_formatted.find(o => o.search === mString.slugify(brandName))
  },
  productBrandDeleted (brandId) {
    this.state.brands = this.state.brands.filter(o => o.id !== brandId)
    this.state.brands_formatted = this.state.brands_formatted.filter(o => o.id !== brandId)
    delete this.state.brands_indexed[brandId]
    eventEmitter.emit('product-brands:fetched')
  },

  async fetchProductIndustrials () {
    if (['fetching', 'done'].indexOf(this.state.productIndustrialsFetched) !== -1) return

    try {
      this.state.productIndustrialsFetched = 'fetching'
      const data = await ApiDeleevProducts.getIndustrials()
      if (data.industrials) this.state.industrials = data.industrials
      if (data.industrials_formatted) this.state.industrials_formatted = data.industrials_formatted
      if (data.industrials_indexed) this.state.industrials_indexed = data.industrials_indexed
      this.state.productIndustrialsFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('product-industrials:fetched')
  },
  getProductIndustrials (variant) {
    return this.state[variant ? `industrials_${variant}` : 'industrials']
  },
  getProductIndustrial (industrialId) {
    if (typeof this.state.industrials_indexed[industrialId] !== 'undefined') {
      return this.state.industrials_formatted[this.state.industrials_indexed[industrialId]]
    }
    return null
  },
  getProductIndustrialByName (industrialName) {
    return this.state.industrials_formatted.find(o => o.name === industrialName)
  },
  productIndustrialDeleted (industrialId) {
    this.state.industrials = this.state.industrials.filter(o => o.id !== industrialId)
    this.state.industrials_formatted = this.state.industrials_formatted.filter(o => o.id !== industrialId)
    delete this.state.industrials_indexed[industrialId]
    eventEmitter.emit('product-industrials:fetched')
  },

  async fetchProductGroups () {
    if (['fetching', 'done'].indexOf(this.state.productGroupsFetched) !== -1) return

    try {
      this.state.productGroupsFetched = 'fetching'
      const data = await ApiDeleevProducts.getGroups()
      if (data.groups) this.state.groups = data.groups
      if (data.groups_formatted) this.state.groups_formatted = data.groups_formatted
      if (data.groups_indexed) this.state.groups_indexed = data.groups_indexed
      this.state.productGroupsFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('product-groups:fetched')
  },
  getProductGroups (variant) {
    return this.state[variant ? `groups_${variant}` : 'groups']
  },
  getProductGroup (groupId) {
    if (this.state.groups_indexed[groupId]) {
      return this.state.groups_formatted[this.state.groups_indexed[groupId]]
    }
    return null
  },
  productGroupDeleted (groupId) {
    this.state.groups = this.state.groups.filter(o => o.id !== groupId)
    this.state.groups_formatted = this.state.groups_formatted.filter(o => o.id !== groupId)
    delete this.state.groups_indexed[groupId]
    eventEmitter.emit('product-groups:fetched')
  },

  async fetchAreaClassifications () {
    if (['fetching', 'done'].indexOf(this.state.areaClassificationsFetched) !== -1) return

    try {
      this.state.areaClassificationsFetched = 'fetching'
      const data = await ApiDeleevAreas.getClassifications({ limit: 50 })
      this.state.areaClassifications = data

      this.state.areaClassificationsFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('area-classifications:fetched')
  },
  getAreaClassifications () {
    return this.state.areaClassifications
  },

  async fetchGroupsAreas () {
    if (['fetching', 'done'].indexOf(this.state.groupsAreasFetched) !== -1) return

    try {
      this.state.groupsAreasFetched = 'fetching'
      const centerIds = [9] // this.state.centers.map(s => s.id)
      for (const centerId of centerIds) {
        let response = await ApiDeleevAreas.getGroupsAreas(centerId)
        response.sort((a, b) => a.name.localeCompare(b.name))
        this.state.groupsAreas[centerId] = response
        this.state.groupsAreas_indexed[centerId] = mObjects.indexBy(response, 'code')
      }
      this.state.groupsAreasFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('groups-areas:fetched')
  },
  getGroupsAreas (centerId) {
    return this.state.groupsAreas[centerId || 9]
  },
  getGroupAreas (centerId, code) {
    return this.state.groupsAreas_indexed[centerId || 9][code] || null
  },
  getAreaGroup (centerId, area) {
    const _groupAreas = this.getGroupsAreas()
    return _groupAreas.filter(o => o.parent_id).find(o => o.areas.indexOf(+area) !== -1) || // first look if sub type exist
      _groupAreas.filter(o => !o.parent_id).find(o => o.areas.indexOf(+area) !== -1)
  },

  async fetchMaxQuantityProductGroups () {
    if (['fetching', 'done'].indexOf(this.state.maxQuantityProductGroupsFetched) !== -1) return

    try {
      this.state.maxQuantityProductGroupsFetched = 'fetching'
      const data = await ApiDeleevProducts.getMaxQuantityGroups()
      if (data.maxQuantityGroups) {
        this.state.maxQuantityGroups = data.maxQuantityGroups
        data.maxQuantityGroups.forEach((o, idx) => {
          this.state.maxQuantityGroups_indexed[o.id] = idx
        })
      }
      this.state.maxQuantityProductGroupsFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('max-quantity-product-groups:fetched')
  },
  getMaxQuantityProductGroups (variant) {
    return this.state[variant ? `maxQuantityGroups_${variant}` : 'maxQuantityGroups']
  },
  getMaxQuantityProductGroup (groupId) {
    if (this.state.maxQuantityGroups_indexed[groupId] !== 'undefined') {
      return this.state.maxQuantityGroups[this.state.maxQuantityGroups_indexed[groupId]]
    }
    return null
  },

  async fetchSuperuFamilles () {
    if (['fetching', 'done'].indexOf(this.state.superuFamillesFetched) !== -1) return

    try {
      this.state.superuFamillesFetched = 'fetching'
      const data = await ApiLabellevieProducts.getSupplierFamilles('superu')
      if (data && data.length) this.state.superuFamilles = data
      this.state.superuFamillesFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('superu-familles:fetched')
  },
  getSuperuFamilles () {
    return this.state.superuFamilles
  },

  async fetchEquipments () {
    if (['fetching', 'done'].indexOf(this.state.equipmentFetched) !== -1) return

    try {
      this.state.equipmentFetched = 'fetching'
      const data = await ApiLabellevieDotations.getDotations({ type: 'pda' })
      if (data.equipment) this.state.equipment = data.equipment
      if (data.equipment_indexed) this.state.equipment_indexed = data.equipment_indexed
      this.state.equipmentFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('equipments:fetched')
  },
  getEquipments (variant) {
    return this.state[variant ? `equipment_${variant}` : 'equipment']
  },
  getEquipment (equipmentId) {
    return this.state.equipment_indexed[equipmentId]
  },

  async fetchProductCorners () {
    if (['fetching', 'done'].indexOf(this.state.productCornersFetched) !== -1) return

    try {
      this.state.productCornersFetched = 'fetching'
      const data = await ApiLabellevieLabo.getProductsCorners()
      if (data.corners) this.state.corners = data.corners
      if (data.corners_indexed) this.state.corners_indexed = data.corners_indexed
      this.state.productCornersFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('product-corners:fetched')
  },
  getProductCorners (variant) {
    return this.state[variant ? `corners_${variant}` : 'corners']
  },
  getProductCorner (cornerId) {
    return this.state.corners_indexed[cornerId]
  },

  async fetchLbvConfig (keyName) {
    if (['fetching', 'done'].indexOf(this.state.lbvConfigsStatus[keyName]) !== -1) return

    try {
      this.state.lbvConfigsStatus[keyName] = 'fetching'
      const data = await ApiLabellevieConfigs.getConfig(keyName)
      if (data) {
        this.state.lbvConfigs[keyName] = data
        this.state.lbvConfigsStatus[keyName] = 'done'
      }
    } catch (e) {}

    eventEmitter.emit(`lbvconfigs-${keyName}:fetched`)
  },
  getLbvConfig (keyName) {
    return this.state.lbvConfigs[keyName]
  },
  formatProductTypologies () {
    this.state.product_typologies.sort((a, b) => (a.index - b.index) || (b.range_end_index - a.range_end_index))
    this.state.product_typologies.forEach((o, idx) => {
      o.search = mString.slugify(o.name)
      o.level = 1
      if (idx !== 0) {
        if (o.range_end_index < this.state.product_typologies[idx - 1].range_end_index) {
          o.level = this.state.product_typologies[idx - 1].level + 1
        } else {
          let _i = 1
          while (this.state.product_typologies[idx - _i] && o.index > this.state.product_typologies[idx - _i].range_end_index) {
            o.level = this.state.product_typologies[idx - _i].level
            _i++
          }
        }
      }
    })

    this.state.product_typologies.forEach((o, idx) => {
      o.descendent = []
      let _i = 1
      while (this.state.product_typologies[idx + _i] && this.state.product_typologies[idx + _i].level > o.level) {
        o.descendent.push(this.state.product_typologies[idx + _i].id)
        _i++
      }
    })
  },
  async fetchProductTypologies () {
    if (['fetching', 'done'].indexOf(this.state.productTypologiesFetched) !== -1) return

    try {
      this.state.productTypologiesFetched = 'fetching'
      const data = await ApiDeleevProductTypology.getProductTypologies({ offset: 0, limit: 1000 })
      this.state.product_typologies = data.results
      this.state.product_typologies_indexed = {}
      this.state.product_typologies_indexed = mObjects.indexBy(this.state.product_typologies, 'id')
      this.formatProductTypologies()
      this.state.productTypologiesFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('product-typologies:fetched')
  },
  getProductTypologies (variant) {
    return this.state[variant ? `product_typologies_${variant}` : 'product_typologies']
  },
  getProductTypology (typologyId) {
    if (typeof this.state.product_typologies_indexed[typologyId] !== 'undefined') {
      return this.state.product_typologies_indexed[typologyId]
    }
    return { id: typologyId, name: '?' }
  },
  // Categories coming from Frichti
  async fetchProductCategories () {
    if (['fetching', 'done'].indexOf(this.state.productCategoryFetched) !== -1) return

    try {
      this.state.productCategoryFetched = 'fetching'
      const data = await ApiDeleevProductCategory.getFrichtiProductCategories({ offset: 0, limit: 1000 })
      this.state.product_categories = data.results
      this.state.product_categories_indexed = {}
      this.state.product_categories_indexed = mObjects.indexBy(this.state.product_categories, 'id')
      // this.formatProductTypologies()
      this.state.productCategoryFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('product-categories:fetched')
  },
  getProductCategories (variant) {
    return this.state[variant ? `product_categories_${variant}` : 'product_categories']
  },
  // Typologies coming from Frichti
  async fetchProductFrichtiTypologies () {
    if (['fetching', 'done'].indexOf(this.state.productFrichtiTypologyFetched) !== -1) return

    try {
      this.state.productFrichtiTypologyFetched = 'fetching'
      const data = await ApiDeleevProductFrichtiTypology.getFrichtiProductTypologies()
      this.state.product_frichti_typolygies = data
      // this.state.product_frichti_typolygies_indexed = {}
      // this.state.product_frichti_typolygies_indexed = mObjects.indexBy(this.state.product_frichti_typolygies, 'id')
      // this.formatProductTypologies()
      this.state.productFrichtiTypologyFetched = 'done'
    } catch (e) {}

    eventEmitter.emit('product-frichti-typologies:fetched')
  },
  getProductFrichtiTypologies (variant) {
    return this.state[variant ? `product_frichti_typolygies_${variant}` : 'product_frichti_typolygies']
  },
  getProductFrichtiTypologiesByTarget (target) {
    return this.state.product_frichti_typolygies[target]
  },

  async setCanteens (...canteenIds) {
    const idsToRetrieve = canteenIds.filter(cid => !Object.keys(this.state.canteensIndexed).includes(cid))
    if (idsToRetrieve) {
      const results = await ApiSearchCanteen.searchCanteens({
        ids: idsToRetrieve
      })
      if (results.found > 0) {
        const canteensById = results.hits.reduce((acc, c) => { acc[c.id] = c; return acc }, {})
        this.state.canteensIndexed = { ...this.state.canteensIndexed, ...canteensById }
      }
    }
  },
  setCanteen (canteen) {
    this.state.canteensIndexed = { ...this.state.canteensIndexed, [Number(canteen.id)]: canteen }
  },
  getCanteen (canteenId) {
    return this.state.canteensIndexed[canteenId]
  }
}
