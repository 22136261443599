'use strict'

import { ApiDeleev } from '../index'

export default {
  getFrichtiProductCategories (params) {
    return new Promise((resolve, reject) => {
      ApiDeleev.get('/admin-products/product-categories/', { params: params })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getFrichtiProductCategoryById (id) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .get(`/admin-products/product-categories/${id}/`, {})
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  createFrichtiProductCategory (data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post('/admin-products/product-categories/', data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateFrichtiProductCategory (id, data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .patch(`/admin-products/product-catgories/${id}/`, data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  }
}
