'use strict'

import { ApiDeleev, FrontLabellevie } from '../index'
import mObjects from '../../mixins/objects'
import mString from '../../mixins/string'

const _brandsResponse = (data) => {
  let _formatted = data.map(o => Object.assign({ search: mString.slugify(o.name) }, o))
  _formatted = mObjects.sort(_formatted, 'name')
  let _indexed = {}
  _formatted.forEach((o, idx) => {
    _indexed[o.id] = idx
    o.index = idx + 1
  })
  return { brands: data, brands_formatted: _formatted, brands_indexed: _indexed }
}

const _industrialsResponse = (data) => {
  let _formatted = data.map(o => Object.assign({ search: mString.slugify(o.name) }, o))
  _formatted = mObjects.sort(_formatted, 'name')
  let _indexed = {}
  _formatted.forEach((o, idx) => { _indexed[o.id] = idx })
  return { industrials: data, industrials_formatted: _formatted, industrials_indexed: _indexed }
}

const _groupsResponse = (data) => {
  let _formatted = data.map(o => Object.assign({ search: mString.slugify(o.name) }, o))
  _formatted = mObjects.sort(_formatted, 'name')
  let _indexed = {}
  _formatted.forEach((o, idx) => {
    _indexed[o.id] = idx
    o.index = idx + 1
  })
  return { groups: data, groups_formatted: _formatted, groups_indexed: _indexed }
}

export default {
  getUnits () {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .get('/units/')
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve({ units: response.data, units_indexed: mObjects.indexBy(response.data) })
        })
        .catch(reject)
    })
  },
  getBrands () {
    let _brands = []
    let _params = { page: 1 }
    const _getBrands = () => {
      return new Promise((resolve, reject) => {
        ApiDeleev
          .get('/admin-products/brands/', { params: _params })
          .then(response => {
            if (response.status >= 400) return reject(response)
            resolve(response.data)
          })
          .catch(reject)
      })
    }

    const _loop = async () => {
      let response = null
      do {
        response = await _getBrands()
        _brands = _brands.concat(response.results)
        _params.page++
      } while (response.next)

      return _brandsResponse(_brands)
    }
    return _loop()
  },
  addBrand (data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post('/admin-products/brands/', data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateBrand (id, data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .patch(`/admin-products/brands/${id}/`, data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  deleteBrand (id) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .delete(`/admin-products/brands/${id}/`)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getIndustrials () {
    let _industrials = []
    let _params = { page: 1 }
    const _getIndustrials = () => {
      return new Promise((resolve, reject) => {
        ApiDeleev
          .get('/admin-products/industrials/', { params: _params })
          .then(response => {
            if (response.status >= 400) return reject(response)
            resolve(response.data)
          })
          .catch(reject)
      })
    }

    const _loop = async () => {
      let response = null
      do {
        response = await _getIndustrials()
        _industrials = _industrials.concat(response.results)
        _params.page++
      } while (response.next)

      return _industrialsResponse(_industrials)
    }
    return _loop()
  },
  addIndustrial (data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post('/admin-products/industrials/', data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateIndustrial (id, data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .patch(`/admin-products/industrials/${id}/`, data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  deleteIndustrial (id) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .delete(`/admin-products/industrials/${id}/`)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getGroups () {
    let _params = { page: 1 }
    return new Promise((resolve, reject) => {
      ApiDeleev
        .get('/product-groups/', { params: _params })
        .then(response => {
          if (response.status >= 400) return reject(response)
          if (!response.data.next) {
            return resolve(_groupsResponse(response.data.results))
          }
          _params.page++
          ApiDeleev
            .get('/product-groups/', { params: _params })
            .then(responsePage2 => {
              if (responsePage2.status >= 400) return reject(responsePage2)
              if (!responsePage2.data.next) {
                return resolve(_groupsResponse([].concat(response.data.results, responsePage2.data.results)))
              }
              _params.page++
              return ApiDeleev
                .get('/product-groups/', { params: _params })
                .then(responsePage3 => {
                  if (responsePage3.status >= 400) return reject(responsePage3)
                  if (!responsePage3.data.next) {
                    return resolve(_groupsResponse([].concat(response.data.results, responsePage2.data.results, responsePage3.data.results)))
                  }
                  return resolve(_groupsResponse([].concat(response.data.results, responsePage2.data.results, responsePage3.data.results)))
                })
            })
        })
        .catch(reject)
    })
  },
  addGroup (data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post('/product-groups/', data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateGroup (id, data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .patch(`/product-groups/${id}/`, data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  deleteGroup (id) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .delete(`/product-groups/${id}/`)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProducts (params) {
    // /!\ not use nested api
    return new Promise((resolve, reject) => {
      ApiDeleev
        .get('/products/', { params: params })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getAdminProducts (params) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .get('/admin-products/nesteds/', { params: params })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductById (id) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .get(`/admin-products/nesteds/${id}/`, { params: { visual_stock_quantity: 1 } })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductValidate (id) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .get(`/admin-products/nesteds/${id}/validate/`)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  createProduct (data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post('/admin-products/nesteds/', data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateProduct (id, data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .patch(`/admin-products/nesteds/${id}/`, data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateProductPicture (id, formData) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post(`/admin-products/products/${id}/update_picture/`, formData)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateToNormalProduct (id) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post(`/admin-products/products/${id}/update_to_normal_product/`)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  createExtraProductPicture (id, formData) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post(`/admin-products/nesteds/${id}/create_extra_picture/`, formData)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  deleteExtraProductPicture (id, formData) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post(`/admin-products/nesteds/${id}/delete_extra_picture/`, formData)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  createPickingProductPicture (id, formData) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post(`/admin-products/nesteds/${id}/create_picture_for_picking/`, formData)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  deletePickingProductPicture (id, formData) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post(`/admin-products/nesteds/${id}/delete_picture_for_picking/`, formData)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductLogs (id, params) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .get(`/admin-products/nesteds/${id}/logs/`, { params: params })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductPreview (id, name) {
    return new Promise((resolve, reject) => {
      FrontLabellevie
        .get(`/produit/${id}/${name}`, { params: { format: 'preview' } })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  alterProductStock (id, data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post(`/admin-products/nesteds/${id}/alter_stock/?visual_stock_quantity=1`, data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getMaxQuantityGroups () {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .get('/max-quantity-product-groups/', { params: { offset: 0, limit: 250 } })
        .then(response => {
          if (response.status >= 400) return reject(response)
          resolve({ maxQuantityGroups: response.data.results })
        })
        .catch(reject)
    })
  },
  getOrderedProducts (params) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .get('/supplier-ordered-products/', { params: params || {} })
        .then(response => {
          if (response.status >= 400) return reject(response)
          resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductStockpillingStatus (id, params) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .get(`/admin-products/nesteds/${id}/stockpilling_status/`, { params: params })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  rezoningProductStock (id, data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post(`/admin-products/nesteds/${id}/rezoning/?visual_stock_quantity=1`, data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  deleteUnusedProduct (id) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .delete(`/admin-products/products/${id}/delete_unused_product/`)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  }
}
