'use strict'

import { ApiLabellevie, ApiLabellevieConfig } from '../index'

const _indexEquipments = (equipments) => {
  var _indexed = {}
  equipments.forEach(o => {
    if (o.serial_number) _indexed[o.serial_number] = o
    if (o.mac_address) _indexed[o.mac_address] = o
  })
  return _indexed
}
export default {
  getDotations (params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get('/team/dotation', { params: params || {}, headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve({ equipment: response.data, equipment_indexed: _indexEquipments(response.data) })
        })
        .catch(reject)
    })
  }
}
