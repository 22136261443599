'use strict'

// async chunk: group-reappro
const VueProductsReappro = () => import(/* webpackChunkName: "group-reappro" */ '../views/Reappro/Products.vue')
const VueReapproOrders = () => import(/* webpackChunkName: "group-reappro" */ '../views/Reappro/Orders.vue')

export default [
  {
    path: '/products-reappro',
    name: 'products-reappro',
    component: VueProductsReappro,
    meta: { permission: 'admin' }
  },
  {
    path: '/reappro-orders',
    name: 'reappro-orders',
    component: VueReapproOrders
  }
]
