'use strict'

// async chunk: group-labo
// async chunk: group-products-labo
const VueProductsLabo = () => import(/* webpackChunkName: "group-products-labo" */ '../views/Labo/Products.vue')
const VueLaboBl = () => import(/* webpackChunkName: "group-products-labo" */ '../views/Labo/DeliveryOrder.vue')
const VueLaboIngredients = () => import(/* webpackChunkName: "group-labo" */ '../views/Labo/Ingredients.vue')
const VueLaboRecipes = () => import(/* webpackChunkName: "group-labo" */ '../views/Labo/Recipes.vue')

export default [
  {
    path: '/products-labo',
    name: 'products-labo',
    component: VueProductsLabo
  },
  {
    path: '/labo-bl',
    name: 'labo-bl',
    component: VueLaboBl,
    meta: { permission: 'admin' }
  },
  {
    path: '/labo-ingredients',
    name: 'labo-ingredients',
    component: VueLaboIngredients,
    meta: { permission: 'labo-manager' }
  },
  {
    path: '/labo-recipes',
    name: 'labo-recipes',
    component: VueLaboRecipes,
    meta: { permission: 'labo-manager' }
  }
]
