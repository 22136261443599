'use strict'

import { ApiDeleev } from '../index'

const _formatAreas = (data) => {
  data.forEach(o => {
    o.areas = []
    if (o.filters && o.filters.length) {
      o.areas = o.filters.map(rang => Array((rang[1] + 1) - rang[0]).fill(0).map((n, idx) => idx + rang[0])).flat()
    }
  })
  return data
}

export default {
  getClassifications (params) {
    return new Promise((resolve, reject) => {
      ApiDeleev.get('/admin-products/area-classifications/', { params: params || {} })
        .then(response => {
          if (response.status >= 400) return reject(response)
          resolve(response.data.results)
        })
        .catch(reject)
    })
  },
  getGroupsAreas (centerId) {
    return new Promise((resolve, reject) => {
      ApiDeleev.get(`/center-group-area/${centerId}/`)
        .then(response => {
          if (response.status >= 400) return reject(response)
          resolve(_formatAreas(response.data))
        })
        .catch(reject)
    })
  }
}
