'use strict'

// async chunk: group-products
const VueProducts = () => import(/* webpackChunkName: "group-products" */ '../views/Products/Products.vue')
const VueProduct = () => import(/* webpackChunkName: "group-products" */ '../views/Products/Product.vue')
const VueProductModal = () => import(/* webpackChunkName: "group-products" */ '../views/Products/ProductModal.vue')
// async chunk: group-products-groups
const VueProductGroups = () => import(/* webpackChunkName: "group-products-groups" */ '../views/Products/Groups.vue')
// async chunk: group-products-brands
const VueProductBrands = () => import(/* webpackChunkName: "group-products-brands" */ '../views/Products/Brands.vue')
// async chunk: group-products-industrials
const VueProductIndustrials = () => import(/* webpackChunkName: "group-products-industrials" */ '../views/Products/Industrials.vue')
// async chunk: group-products-substitute
// const VueProductsSubstitute = () => import(/* webpackChunkName: "group-products-substitute" */ '../views/Substitute.vue')
// async chunk: group-logs
const VueLogsDLCTour = () => import(/* webpackChunkName: "group-logs" */ '../views/Products/LogsDLCTour.vue')
const VueProductsCreditsLogs = () => import(/* webpackChunkName: "group-logs" */ '../views/Products/LogsCredits.vue')
// async chunk: group-logspda
const VueProductsLogsPDA = () => import(/* webpackChunkName: "group-logspda" */ '../views/Products/LogsPDA.vue')
const VueProductsLogsPDAScoped = () => import(/* webpackChunkName: "group-logspda" */ '../views/Products/LogsPDAScoped.vue')
const VueProductsLogsPDAScopedModal = () => import(/* webpackChunkName: "group-logspda" */ '../views/Products/LogsPDAScopedModal.vue')
// async chunk: group-ingredients
const VueIngredients = () => import(/* webpackChunkName: "group-ingredients" */ '../views/Products/Ingredients.vue')
const VueIngredient = () => import(/* webpackChunkName: "group-ingredients" */ '../views/Products/Ingredient.vue')
const VueIngredientModal = () => import(/* webpackChunkName: "group-ingredients" */ '../views/Products/IngredientModal.vue')
// async chunk: group-products-qiqd
const VueProductsQiQd = () => import(/* webpackChunkName: "group-products-qiqd" */ '../views/Products/QiQd.vue')
const VueProductsQiQdRules = () => import(/* webpackChunkName: "group-products-qiqd" */ '../views/Products/QiQdRules.vue')
const VueProductsSalesStats = () => import(/* webpackChunkName: "group-products-qiqd" */ '../views/Products/SalesStats.vue')
// async chunk: group-products-keywords
const VueProductKeywords = () => import(/* webpackChunkName: "group-products-keywords" */ '../views/Products/Keywords.vue')
// async chunk: group-products-create
const VueProductsCreate = () => import(/* webpackChunkName: "group-products-create" */ '../views/Products/Create.vue')
// async chunk: group-products-concurrent
const VueProductsConcurrent = () => import(/* webpackChunkName: "group-products-concurrent" */ '../views/Products/Concurrent.vue')
// async chunk: group-products-margins
const VueProductsMargins = () => import(/* webpackChunkName: "group-products-margins" */ '../views/Products/Margins.vue')
// async chunk: group-product-typologies
const VueProductTypologies = () => import(/* webpackChunkName: "group-product-typologies" */ '../views/Products/Typologies.vue')
const VueProductTypology = () => import(/* webpackChunkName: "group-product-typologies" */ '../views/Products/Typology.vue')
const VueProductTypologyModal = () => import(/* webpackChunkName: "group-product-typologies" */ '../views/Products/TypologyModal.vue')
const VueProductLogScan = () => import(/* webpackChunkName: "group-product-logsscan" */ '../views/Products/LogScan.vue')
// async chunk: group-product-edp
const VueEdps = () => import(/* webpackChunkName: "group-product-edp" */ '../views/Products/Epds.vue')
const VueEdp = () => import(/* webpackChunkName: "group-product-edp" */ '../views/Products/Epd.vue')
const VueSearchEpds = () => import(/* webpackChunkName: "group-product-edp" */ '../views/Products/SearchEpds.vue')

export default [
  {
    path: '/ingredients',
    name: 'ingredients',
    component: VueIngredients,
    meta: { permission: 'admin' }
  },
  {
    path: '/ingredients/:id',
    name: 'ingredient',
    component: VueIngredientModal,
    beforeEnter: (to, from, next) => {
      if (['ingredients', 'products'].indexOf(from.name) !== -1) {
        to.matched[0].components.default = from.matched[0].components.default
        to.matched[0].components.modal = VueIngredientModal
      } else {
        to.matched[0].components.default = VueIngredient
        to.matched[0].components.modal = false
      }
      next()
    },
    meta: { permission: 'admin' }
  },
  {
    path: '/products',
    name: 'products',
    component: VueProducts
  },
  {
    path: '/products/:id',
    name: 'product',
    component: VueProductModal,
    beforeEnter: (to, from, next) => {
      if ([
        'products',
        'products-qiqd',
        'products-reappro',
        'reappro-orders',
        // 'products-substitute',
        'products-create',
        'products-keywords',
        'supplier-orders-arrival',
        'products-logs-pda',
        'products-labo',
        'products-concurrent',
        'products-margins',
        'products-logs-credits',
        'labo-bl',
        'products-sales-stats',
        'search-ingredients',
        'categories-products',
        'recipes-ingredients',
        'recipes-ingredient',
        'recipes-ingredients-products',
        'recipes-utensil',
        'products-logs-dlctour',
        'supplier-order-form-create',
        'recipes',
        'recipe',
        'products-logs-scan'
      ].indexOf(from.name) !== -1) {
        to.matched[0].components.default = from.matched[0].components.default
        to.matched[0].components.modal = VueProductModal
      } else {
        to.matched[0].components.default = VueProduct
        to.matched[0].components.modal = false
      }
      next()
    },
    meta: { permission: ['MeS', 'support'] }
  },
  {
    path: '/product-brands',
    name: 'product-brands',
    component: VueProductBrands,
    meta: { permission: 'admin' }
  },
  {
    path: '/product-industrials',
    name: 'product-industrials',
    component: VueProductIndustrials,
    meta: { permission: 'admin' }
  },
  {
    path: '/product-groups',
    name: 'product-groups',
    component: VueProductGroups,
    meta: { permission: 'admin' }
  },
  {
    path: '/products-keywords',
    name: 'products-keywords',
    component: VueProductKeywords,
    meta: { permission: 'admin' }
  },
  /*
  {
    path: '/products-substitute',
    name: 'products-substitute',
    component: VueProductsSubstitute,
    meta: { permission: 'admin' }
  },
  */
  {
    path: '/products-create',
    name: 'products-create',
    component: VueProductsCreate,
    meta: { permission: 'admin-multi-centers' }
  },
  {
    path: '/products-concurrent',
    name: 'products-concurrent',
    component: VueProductsConcurrent,
    meta: { permission: 'admin' }
  },
  {
    path: '/products-margins',
    name: 'products-margins',
    component: VueProductsMargins,
    meta: { permission: 'category-manager' }
  },
  {
    path: '/products-qiqd',
    name: 'products-qiqd',
    component: VueProductsQiQd,
    meta: { permission: 'MeS RF' }
  },
  {
    path: '/products-qiqd-rules',
    name: 'products-qiqd-rules',
    component: VueProductsQiQdRules,
    meta: { permission: 'super-admin' }
  },
  {
    path: '/products-sales-stats',
    name: 'products-sales-stats',
    component: VueProductsSalesStats,
    meta: { permission: 'super-admin' }
  },
  {
    path: '/products-logs-dlctour',
    name: 'products-logs-dlctour',
    component: VueLogsDLCTour,
    meta: { permission: 'admin' }
  },
  {
    path: '/products-logs-pda',
    name: 'products-logs-pda',
    component: VueProductsLogsPDA,
    meta: { permission: 'admin' }
  },
  {
    path: '/products-logs-credits',
    name: 'products-logs-credits',
    component: VueProductsCreditsLogs,
    meta: { permission: 'super-admin' }
  },
  {
    path: '/products-logs-pda/:scope/:id',
    name: 'products-logs-pda-scoped',
    component: VueProductsLogsPDAScopedModal,
    beforeEnter: (to, from, next) => {
      if (['products-logs-pda', 'products', 'supplier-orders-arrival', 'supplier-orders'].indexOf(from.name) !== -1) {
        to.matched[0].components.default = from.matched[0].components.default
        to.matched[0].components.modal = VueProductsLogsPDAScopedModal
      } else {
        to.matched[0].components.default = VueProductsLogsPDAScoped
        to.matched[0].components.modal = false
      }
      next()
    },
    meta: { permission: 'MeS' }
  },
  {
    path: '/product-typologies',
    name: 'product-typologies',
    component: VueProductTypologies,
    meta: { permission: 'super-admin' }
  },
  {
    path: '/product-typologies/:id',
    name: 'product-typology',
    component: VueProductTypologyModal,
    beforeEnter: (to, from, next) => {
      if (['product-typologies', 'products'].indexOf(from.name) !== -1) {
        to.matched[0].components.default = from.matched[0].components.default
        to.matched[0].components.modal = VueProductTypologyModal
      } else {
        to.matched[0].components.default = VueProductTypology
        to.matched[0].components.modal = false
      }
      next()
    },
    meta: { permission: 'admin' }
  },
  {
    path: '/products-logs-scan',
    name: 'products-logs-scan',
    component: VueProductLogScan,
    meta: { permission: 'Zone' }
  },
  {
    path: '/epds',
    name: 'epds',
    component: VueEdps,
    meta: { permission: 'admin' }
  },
  {
    path: '/epd',
    name: 'epdbyweek',
    props: (route) => ({ week: route.query.week, year: route.query.year }),
    component: VueEdp,
    meta: { permission: 'admin' }
  },
  {
    path: '/search-epds',
    name: 'searchepds',
    component: VueSearchEpds,
    meta: { permission: 'admin' }
  }
]
