'use strict'

import files from './mixins/files'
import dates from './mixins/dates'
import string from './mixins/string'
import numbers from './mixins/numbers'
import objects from './mixins/objects'
import products from './mixins/products'
import orders from './mixins/orders'
import ingredients from './mixins/ingredients'
import suppliers from './mixins/suppliers'
import centers from './mixins/centers'
import sites from './mixins/sites'
import sections from './mixins/sections'
import areas from './mixins/area'
import users from './mixins/users'
import recipes from './mixins/recipes'
import admin from './mixins/admin'

const _allMethods = [
  { prefix: 'files', methods: files },
  { prefix: 'dates', methods: dates },
  { prefix: 'string', methods: string },
  { prefix: 'numbers', methods: numbers },
  { prefix: 'objects', methods: objects },
  { prefix: 'products', methods: products },
  { prefix: 'orders', methods: orders },
  { prefix: 'suppliers', methods: suppliers },
  { prefix: 'centers', methods: centers },
  { prefix: 'sites', methods: sites },
  { prefix: 'sections', methods: sections },
  { prefix: 'ingredients', methods: ingredients },
  { prefix: 'areas', methods: areas },
  { prefix: 'users', methods: users },
  { prefix: 'recipes', methods: recipes },
  { prefix: 'admin', methods: admin }
]
let _methods = {}
_allMethods.forEach((o) => {
  Object.keys(o.methods).forEach((name) => {
    _methods[o.prefix + string.capitalize(name)] = o.methods[name]
  })
})

export default {
  methods: _methods
}
