'use strict'

import { ApiLabellevie, ApiLabellevieConfig } from '../index'

export default {
  getConfig (keyName) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/labellevie/configs/${keyName}`, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateConfig (keyName, data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .put(`/labellevie/configs/${keyName}`, data, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  }
}
