'use strict'

import { ApiDeleev } from '../index'
import mString from '../../mixins/string'
import mObjects from '../../mixins/objects'

let _formatted = []
let _treeselect = []
let _indexed = {}
const _formatCategories = (categories, root, parent, level, parentActive, thumb, treeselect) => {
  categories.forEach((category, idx) => {
    let _category = Object.assign({
      parent_active: parentActive,
      name: category.name_web,
      thumb: category.picture_mobile_thumbnail || thumb || '',
      thumb_web: category.picture_thumbnail || thumb || '',
      thumb_terminal: category.picture_terminal_thumbnail || thumb || '',
      level: level,
      root: root,
      parent: parent,
      search: mString.slugify([
        root ? (root + ' / ') : '',
        parent || '',
        category.name_web
      ].join('')),
      search_light: mString.slugify([
        root ? (root + ' / ') : '',
        category.name_web
      ].join('')),
      descendents: category.descendent ? category.descendent.length : 0,
      descendents_ids: category.descendent
        ? category.descendent.map(c => ({ type: 'cat', id: c.id, is_active: c.is_active }))
        : [],
      is_first: idx === 0,
      is_last: idx === (categories.length - 1),
      sort: _formatted.length + 1,
      is_deleted: false
    }, mObjects.pick(category, [
      'id',
      'is_active',
      'is_locked',
      'has_children',
      'category_content_type',
      'recipes_list_id',
      'number_of_products',
      'total_number_of_products'
    ]))

    const _hasDescendent = category.descendent && category.descendent.length
    const _parentActive = parentActive && category.is_active

    let _select = Object.assign({
      id: _category.id,
      label: _category.name,
      parent_active: _parentActive
    }, mObjects.pick(_category, ['root', 'parent', 'is_active', 'is_deleted', 'is_locked', 'number_of_products', 'category_content_type']))

    if (_hasDescendent) {
      _select.children = []
    }

    _formatted.push(_category)
    treeselect.push(_select)
    _indexed[category.id] = _formatted.length - 1

    if (_hasDescendent) {
      const _root = level === 1 ? category.name_web : root
      const _parent = level > 1 ? (parent + category.name_web + ' / ') : parent

      _formatCategories(category.descendent, _root, _parent, level + 1, _parentActive, category.picture_mobile || thumb, _select.children)
    }
  })
}

export default {
  getCategories (siteId) {
    return new Promise((resolve, reject) => {
      const _params = { site_id: siteId, types: 'all', with_sections: '1' }
      ApiDeleev.get('/admin-categories/tree/', { params: _params })
        .then(response => {
          if (response.status >= 400) return reject(response)

          _formatted = []
          _treeselect = []
          _indexed = {}
          _formatCategories(response.data, '', '', 1, true, null, _treeselect)
          resolve({
            categories: response.data,
            categories_formatted: _formatted,
            categories_treeselect: _treeselect,
            categories_indexed: _indexed
          })
        })
        .catch(reject)
    })
  },
  getCategoryById (id) {
    return new Promise((resolve, reject) => {
      ApiDeleev.get(`/admin-categories/${id}/`)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  createCategory (data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post(`/admin-categories/`, data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateCategory (id, data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .patch(`/admin-categories/${id}/`, data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  _updateCategoryPicture (url, data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post(url, data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateCategoryWebPicture (id, data) {
    return this._updateCategoryPicture(`/admin-categories/${id}/update_picture/`, data)
  },
  updateCategoryMobilePicture (id, data) {
    return this._updateCategoryPicture(`/admin-categories/${id}/update_picture_mobile/`, data)
  },
  updateCategoryTerminalPicture (id, data) {
    return this._updateCategoryPicture(`/admin-categories/${id}/update_picture_terminal/`, data)
  },
  deleteCategory (id) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .delete(`/admin-categories/${id}/`)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateProductsOrder (categoryId, data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post(`/admin-categories/${categoryId}/bulk/`, data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  refreshCache (siteId) {
    return new Promise((resolve, reject) => {
      ApiDeleev.get('/admin-categories/refresh/', { params: { site_id: siteId } })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProducts (categoryId, siteId) {
    return new Promise((resolve, reject) => {
      ApiDeleev.get(`/admin-categories/${categoryId}/all_products/`, { params: { site_id: siteId } })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  }
}
