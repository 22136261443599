<template>
  <b-container class="page-home">
    <div class="text-center pt-4">
      <h1 class="text-deleev text-deleev-title">Deleev</h1>
      <hr />
      <div class="d-md-flex align-items-start justify-content-center">
        <div class="menu-links d-flex flex-column justify-content-center align-items-stretch mx-2">
          <div><router-link to="/products" class="btn btn-sm btn-light mt-1">Produits</router-link></div>
          <div><router-link v-if="userIsAdmin" to="/product-brands" class="btn btn-sm btn-light mt-1">Marques (produit)</router-link></div>
          <div><router-link v-if="userIsAdmin" to="/product-industrials" class="btn btn-sm btn-light mt-1">Industriels / Fabricants (produit)</router-link></div>
          <div><router-link v-if="userIsAdmin" to="/product-groups" class="btn btn-sm btn-light mt-1">Groupes (produit)</router-link></div>
          <div><router-link v-if="userIsAdmin" to="/ingredients" class="btn btn-sm btn-light mt-1">Ingrédients</router-link></div>
          <!-- <div><router-link v-if="userIsAdmin" to="/epds" class="btn btn-sm btn-light mt-1">EPD</router-link></div>
          <div><router-link v-if="userIsAdmin" to="/search-epds" class="btn btn-sm btn-light mt-1">Rechercher des EPDs</router-link></div> -->
          <div><router-link v-if="userIsSuperAdmin" to="/product-typologies" class="btn btn-sm btn-light mt-1">Typologies de produits</router-link></div>
          <div><router-link v-if="userIsAdmin" to="/search-groups" class="btn btn-sm btn-light mt-1">Recherche et mots-clés</router-link></div>
          <div><router-link v-if="userIsAdmin" to="/products-keywords" class="btn btn-sm btn-light mt-1">Gestion des mots-clés produits</router-link></div>
          <!-- <div><router-link v-if="userIsAdmin" to="/products-substitute" class="btn btn-sm btn-light mt-1">Produits de remplacement</router-link></div> -->
          <div><router-link v-if="userIsSuperAdmin" to="/products-logs-credits" class="btn btn-sm btn-light mt-3">Logs Crédits</router-link></div>
          <div><router-link v-if="userIsAdmin" to="/products-logs-dlctour" class="btn btn-sm btn-light mt-1">Logs DLC Tour</router-link></div>
          <div><router-link v-if="userIsAdmin" to="/products-logs-pda" class="btn btn-sm btn-light mt-1">Logs PDA</router-link></div>
          <div><router-link v-if="userCanZone" to="/products-logs-scan" class="btn btn-sm btn-light mt-1">Logs Scans</router-link></div>
          <div><router-link v-if="userIsAdminMultiCenters" to="/products-create" class="btn btn-sm btn-light mt-3">Ajouter des produits</router-link></div>
          <div><router-link v-if="userIsAdmin" to="/products-concurrent" class="btn btn-sm btn-light mt-1">Prix concurrents</router-link></div>
          <div><router-link v-if="userIsCategoryManager" to="/products-margins" class="btn btn-sm btn-light mt-1">Marges produits</router-link></div>
          <div><router-link v-if="userCanMeSRF" to="/products-qiqd" class="btn btn-sm btn-light mt-3">QI/QD journalières</router-link></div>
          <div><router-link v-if="userIsSuperAdmin" to="/products-qiqd-rules" class="btn btn-sm btn-light mt-1">Règles de calcul QI/QD</router-link></div>
          <div><router-link v-if="userIsSuperAdmin" to="/products-sales-stats" class="btn btn-sm btn-light mt-1">Stats ventes (réelles & estimations)</router-link></div>
          <!--<div><router-link v-if="userIsAdmin" to="/ubereats" class="btn btn-sm btn-light mt-1">Uber Eats</router-link></div>-->
        </div>
        <div class="menu-links d-flex flex-column justify-content-center align-items-stretch mx-2">
          <div><router-link v-if="userIsAdmin || userIsSupport" to="/centers" class="btn btn-sm btn-light mt-1">Centres</router-link></div>
          <div><router-link v-if="userIsAdmin || userIsSupport" to="/sites" class="btn btn-sm btn-light mt-1">Sites</router-link></div>
          <div><router-link v-if="userIsAdmin" to="/suppliers" class="btn btn-sm btn-light mt-1">Fournisseurs</router-link></div>
          <div><router-link v-if="userCanMeS" to="/supplier-orders/last-arrivals" class="btn btn-sm btn-light mt-1">Derniers arrivages</router-link></div>
          <div><router-link v-if="userCanMeS" to="/supplier-orders" class="btn btn-sm btn-light mt-1">Liste des commandes</router-link></div>
          <div><router-link to="/barcodes" class="btn btn-sm btn-light mt-1">Barcode & Qr-code</router-link></div>
          <div><router-link v-if="userIsCategoryManager" to="/recipes/recipes" class="btn btn-sm btn-light mt-3">Recettes</router-link></div>
          <div><router-link v-if="userIsCategoryManager" to="/recipes/ingredients" class="btn btn-sm btn-light mt-1">Ingrédients</router-link></div>
          <div><router-link v-if="userIsCategoryManager" to="/recipes/ingredients-products" class="btn btn-sm btn-light mt-1">Produits (ingrédients)</router-link></div>
          <div><router-link v-if="userIsCategoryManager" to="/recipes/utensils" class="btn btn-sm btn-light mt-1">Ustensiles</router-link></div>
          <div><router-link v-if="userIsCategoryManager" to="/recipes/measures" class="btn btn-sm btn-light mt-1">Mesures</router-link></div>
          <div><router-link v-if="userIsCategoryManager" to="/recipes/tags" class="btn btn-sm btn-light mt-1">Tags</router-link></div>
          <div><router-link v-if="userIsCategoryManager" to="/recipes/stats" class="btn btn-sm btn-light mt-1">Stats (recettes)</router-link></div>
          <div><router-link to="/products-labo" class="btn btn-sm btn-light mt-3">Labo - Produits</router-link></div>
          <div><router-link v-if="userIsAdmin" to="/labo-bl" class="btn btn-sm btn-light mt-1">Labo - Bon de livraison</router-link></div>
          <div><router-link v-if="userIsLaboManager" to="/labo-ingredients" class="btn btn-sm btn-light mt-1">Labo - Ingrédients</router-link></div>
          <div><router-link v-if="userIsLaboManager" to="/labo-recipes" class="btn btn-sm btn-light mt-1">Labo - Recettes</router-link></div>
          <div><router-link v-if="userIsCategoryManager" to="/section-groups" class="btn btn-sm btn-light mt-4">Navigation (Sections)</router-link></div>
          <!--<div><router-link v-if="userIsCategoryManager" to="/navigation" class="btn btn-sm btn-light mt-1">Navigation - desktop</router-link></div>-->
          <div><router-link v-if="userIsCategoryManager" to="/categories-products" class="btn btn-sm btn-light mt-1">Catégories - produits</router-link></div>
          <div><router-link v-if="userIsCategoryManager" to="/carousels" class="btn btn-sm btn-light mt-1">Carrousels</router-link></div>
        </div>
      </div>
      <div class="mt-5">
        <b-button variant="link" size="sm" class="text-primary text-decoration-none" @click="$emit('logout')"><i class="fas fa-sign-out-alt"></i> déconnexion</b-button>
      </div>
    </div>
  </b-container>
</template>

<script>
import store from '../store'

export default {
  name: 'Home',
  data () {
    return {
      userIsSuperAdmin: store.userCan('super-admin'),
      userIsCategoryManager: store.userCan('category-manager'),
      userIsLaboManager: store.userCan('labo-manager'),
      userIsAdmin: store.userCan('admin'),
      userIsAdminMultiCenters: store.userCan('admin-multi-centers'),
      userIsSupport: store.userCan('support'),
      userCanMeS: store.userCan('MeS'),
      userCanMeSRF: store.userCan('MeS RF'),
      userCanZone: store.userCan('Zone')
    }
  }
}
</script>

<style scoped>
  .menu-links > div > a {
    width: 240px;
  }
</style>
