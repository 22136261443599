'use strict'

import mObjects from './objects'

const _ingredientTypes = [
  { value: 15, text: 'Abats' },
  { value: 43, text: 'Aide culinaire' },
  { value: 29, text: 'Alcool et Sirops' },
  { value: 41, text: 'Aromate' },
  { value: 47, text: 'Boisson' },
  { value: 37, text: 'Boucherie' },
  { value: 45, text: 'Boulangerie' },
  { value: 21, text: 'Charcuterie' },
  { value: 42, text: 'Condiment' },
  { value: 4, text: 'Coquillages et crustacés' },
  { value: 36, text: 'Crèmerie' },
  { value: 35, text: 'Épicerie' },
  { value: 31, text: 'Épices' },
  { value: 16, text: 'Foie Gras' },
  { value: 39, text: 'Fromage' },
  { value: 1, text: 'Fruit' },
  { value: 32, text: 'Fruits secs' },
  { value: 27, text: 'Herbe aromatique' },
  { value: 30, text: 'Huiles et Vinaigres' },
  { value: 25, text: 'Légumes' },
  { value: 46, text: 'Légumes secs' },
  { value: 3, text: 'Poisson' },
  { value: 33, text: 'Sauces' },
  { value: 10, text: 'Volaille' }
]

const _ingtedientUnits = [
  {
    value: 1,
    text: 'Kg',
    measures: [
      { name: 'kg', value: 1, is_default: true, readonly: true },
      { name: 'g', value: 1000, is_default: true, readonly: true }
    ]
  },
  {
    value: 2,
    text: 'L',
    measures: [
      { name: 'l', value: 1, is_default: true, readonly: true },
      { name: 'cl', value: 100, is_default: true, readonly: true },
      { name: 'ml', value: 1000, is_default: true, readonly: true }
    ]
  },
  {
    value: 3,
    text: 'Unité',
    measures: [
      { name: 'unité', value: 1, is_default: true, readonly: true },
      { name: 'bouquet', value: 1, is_default: false, readonly: true },
      { name: 'botte', value: 1, is_default: false, readonly: true },
      { name: 'brin', value: 1, is_default: false, readonly: false },
      { name: 'feuille', value: 1, is_default: false, readonly: false },
      { name: 'gousse', value: 1, is_default: false, readonly: false },
      { name: 'tige', value: 1, is_default: false, readonly: false },
      { name: 'tranche', value: 1, is_default: false, readonly: false }
    ]
  }
]

const _ingtedientSupplierTypes = mObjects.sort([
  { value: 1, text: 'Alcool et Sirops' },
  { value: 2, text: 'Boucherie' },
  { value: 3, text: 'Boulangerie' },
  { value: 4, text: 'Charcuterie' },
  { value: 5, text: 'Épicerie' },
  { value: 6, text: 'Fromager' },
  { value: 7, text: 'Poissonnier' },
  { value: 8, text: 'Primeur' },
  { value: 9, text: 'Supermarché' },
  { value: 10, text: 'Cuisine Deleev' }
], 'text')

export default {
  ingredientTypes: () => _ingredientTypes,
  ingredientType: (type) => _ingredientTypes.find(o => o.value === type) || { value: type, text: '?' },
  ingtedientUnits: () => _ingtedientUnits,
  ingtedientUnit: (unitId) => _ingtedientUnits.find(o => o.value === unitId) || { value: unitId, text: '?' },
  ingtedientSupplierTypes: () => _ingtedientSupplierTypes,
  ingtedientSupplierType: (typeId) => _ingtedientSupplierTypes.find(o => o.value === typeId) || { value: typeId, text: '?' }
}
