'use strict'

import { ApiDeleev } from '../index'
import mObjects from '../../mixins/objects'
import mString from '../../mixins/string'

let _formatted = []
let _indexed = {}
const _formatSuppliers = (suppliers) => {
  suppliers.forEach(o => {
    o.search = mString.slugify(o.name)
    o.by_centers.sort((a, b) => a.center > b.center ? 1 : -1)
  })
  suppliers.forEach(o => {
    o.by_centers_indexed = o.by_centers.reduce((routineMap, supplierCenter) => {
      routineMap[supplierCenter.center] = supplierCenter
      return routineMap
    }, {})
  })
  suppliers = mObjects.sort(suppliers, 'name')
  _formatted = suppliers
    .filter(o => { return !o.is_hidden })
    .map(o => mObjects.pick(o, ['id', 'name', 'category', 'is_internal', 'search']))
  suppliers.forEach((o, idx) => { _indexed[o.id] = idx })
}

export default {
  getSuppliers () {
    return new Promise((resolve, reject) => {
      ApiDeleev.get('/suppliers/')
        .then(response => {
          if (response.status >= 400) return reject(response)

          _formatSuppliers(response.data)
          resolve({
            suppliers: response.data,
            suppliers_formatted: _formatted,
            suppliers_indexed: _indexed
          })
        })
        .catch(reject)
    })
  },
  getSupplierById (id) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .get(`/suppliers/${id}/`, {})
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  createSupplier (data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post('/suppliers/', data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateSupplier (id, data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .patch(`/suppliers/${id}/`, data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getSupplierCategories () {
    return new Promise((resolve, reject) => {
      ApiDeleev.get('/suppliers/categories/')
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  }
}
