'use strict'

import store from '@/store'

const BASE_URL_LABELLVIE = 'https://www.labellevie.com'
const BASE_URL_BAMCOURSES = 'https://www.bamcourses.com'
const BASE_URL_LAGRANDEEPICERIE = 'https://chezvous.lagrandeepicerie.com/'
const BASE_URL_FRICHTIMARKET = 'https://www.frichtimarket.com'
const BASE_URL_FRICHTIEVENTS = 'https://www.frichtievents.com'
const BASE_URL_FRICHTI = 'https://www.frichti.eu'

const _sitesBaseUrl = {
  1: BASE_URL_LABELLVIE,
  10: BASE_URL_LABELLVIE,
  8: BASE_URL_BAMCOURSES,
  9: BASE_URL_BAMCOURSES,
  11: BASE_URL_BAMCOURSES,
  1012: BASE_URL_LAGRANDEEPICERIE,
  1013: BASE_URL_FRICHTIMARKET,
  1014: BASE_URL_FRICHTIEVENTS,
  1015: BASE_URL_FRICHTI
}

const _getSite = (siteId) => {
  return store.getSite(siteId) || { id: siteId, name: '?' }
}
const _getSiteBaseUrl = (siteId) => {
  return _sitesBaseUrl[siteId] || ''
}

export default {
  getSite: _getSite,
  getSiteBaseUrl: _getSiteBaseUrl
}
