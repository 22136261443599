'use strict'

// async chunk: group-recipes
const VueRecipes = () => import(/* webpackChunkName: "group-recipes" */ '../views/Recipes/Recipes.vue')
const VueRecipe = () => import(/* webpackChunkName: "group-recipes" */ '../views/Recipes/Recipe.vue')
const VueRecipeModal = () => import(/* webpackChunkName: "group-recipes" */ '../views/Recipes/RecipeModal.vue')
const VueIngredients = () => import(/* webpackChunkName: "group-recipes" */ '../views/Recipes/Ingredients.vue')
const VueIngredientsProducts = () => import(/* webpackChunkName: "group-recipes" */ '../views/Recipes/IngredientsProducts.vue')
const VueIngredient = () => import(/* webpackChunkName: "group-recipes" */ '../views/Recipes/Ingredient.vue')
const VueIngredientModal = () => import(/* webpackChunkName: "group-recipes" */ '../views/Recipes/IngredientModal.vue')
const VueUtensils = () => import(/* webpackChunkName: "group-recipes" */ '../views/Recipes/Utensils.vue')
const VueUtensil = () => import(/* webpackChunkName: "group-recipes" */ '../views/Recipes/Utensil.vue')
const VueUtensilModal = () => import(/* webpackChunkName: "group-recipes" */ '../views/Recipes/UtensilModal.vue')
const VueMeasures = () => import(/* webpackChunkName: "group-recipes" */ '../views/Recipes/Measures.vue')
const VueTags = () => import(/* webpackChunkName: "group-recipes" */ '../views/Recipes/Tags.vue')
const VueRecipesStats = () => import(/* webpackChunkName: "group-recipes" */ '../views/Recipes/Stats.vue')

export default [
  {
    path: '/recipes/recipes',
    name: 'recipes',
    component: VueRecipes,
    meta: { permission: 'category-manager' }
  },
  {
    path: '/recipes/recipes/:id',
    name: 'recipe',
    component: VueRecipeModal,
    beforeEnter: (to, from, next) => {
      if (['recipes', 'recipes-stats', 'product', 'products', 'recipes-ingredient', 'recipes-utensil', 'categories-products'].indexOf(from.name) !== -1) {
        to.matched[0].components.default = from.matched[0].components.default
        to.matched[0].components.modal = VueRecipeModal
      } else {
        to.matched[0].components.default = VueRecipe
        to.matched[0].components.modal = false
      }
      next()
    },
    meta: { permission: 'category-manager' }
  },
  {
    path: '/recipes/ingredients',
    name: 'recipes-ingredients',
    component: VueIngredients,
    meta: { permission: 'category-manager' }
  },
  {
    path: '/recipes/ingredients/:id',
    name: 'recipes-ingredient',
    component: VueIngredientModal,
    beforeEnter: (to, from, next) => {
      if (['recipes-ingredients', 'search-ingredients', 'recipe', 'product'].indexOf(from.name) !== -1) {
        to.matched[0].components.default = from.matched[0].components.default
        to.matched[0].components.modal = VueIngredientModal
      } else {
        to.matched[0].components.default = VueIngredient
        to.matched[0].components.modal = false
      }
      next()
    },
    meta: { permission: 'category-manager' }
  },
  {
    path: '/recipes/ingredients-products',
    name: 'recipes-ingredients-products',
    component: VueIngredientsProducts,
    meta: { permission: 'category-manager' }
  },
  {
    path: '/recipes/utensils',
    name: 'recipes-utensils',
    component: VueUtensils,
    meta: { permission: 'category-manager' }
  },
  {
    path: '/recipes/utensils/:id',
    name: 'recipes-utensil',
    component: VueUtensilModal,
    beforeEnter: (to, from, next) => {
      if (['recipes-utensils', 'product', 'recipe'].indexOf(from.name) !== -1) {
        to.matched[0].components.default = from.matched[0].components.default
        to.matched[0].components.modal = VueUtensilModal
      } else {
        to.matched[0].components.default = VueUtensil
        to.matched[0].components.modal = false
      }
      next()
    },
    meta: { permission: 'category-manager' }
  },
  {
    path: '/recipes/measures',
    name: 'recipes-measures',
    component: VueMeasures,
    meta: { permission: 'category-manager' }
  },
  {
    path: '/recipes/tags',
    name: 'recipes-tags',
    component: VueTags,
    meta: { permission: 'category-manager' }
  },
  {
    path: '/recipes/stats',
    name: 'recipes-stats',
    component: VueRecipesStats,
    meta: { permission: 'category-manager' }
  }
]
