'use strict'

import axios from 'axios'
import { ApiLabellevie, ApiLabellevieConfig } from '../index'
import mObjects from '../../mixins/objects'

export default {
  getProducts (params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get('/products/labo-monitoring', { params: params || {}, headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateProduct (data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .post('/products/labo-monitoring/', data, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  syncSupplierOrders (data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .post('/products/labo-monitoring/sync-suppliers-orders', data, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getDispatchSupplierOrder (params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get('/products/labo-monitoring/dispatch-supplier-order', { params: params || {}, headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  saveDispatchSupplierOrder (data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .post(`/products/labo-monitoring/dispatch-supplier-order`, data, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductsCorners () {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/products/labo-monitoring/products/corners`, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve({ corners: response.data, corners_indexed: mObjects.indexBy(response.data) })
        })
        .catch(reject)
    })
  },
  updateProductCorner (cornerId, data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .put(`/products/labo-monitoring/products/corners/${cornerId}`, data, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductsCornertype () {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/products/labo-monitoring/products/cornertype`, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateProductCornertype (productId, data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .put(`/products/labo-monitoring/products/${productId}/cornertype`, data, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getIngredients (params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get('/labo/ingredients', { params: params || {}, headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  createIngredient (data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .post(`/labo/ingredients`, data, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateIngredient (ingredientId, data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .put(`/labo/ingredients/${ingredientId}`, data, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getRecipes (params) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get('/labo/recipes', { params: params || {}, headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getRecipe (recipeId) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .get(`/labo/recipes/${recipeId}`, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  createRecipe (data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .post(`/labo/recipes`, data, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateRecipe (recipeId, data) {
    return new Promise((resolve, reject) => {
      ApiLabellevie
        .put(`/labo/recipes/${recipeId}`, data, { headers: ApiLabellevieConfig.getHeadersWithByPassToken() })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  uploadRecipePicture (formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${ApiLabellevieConfig.getUploadBaseUrl()}/media/recipes-labo/upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  }
}
