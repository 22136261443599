'use strict'

import Axios from 'axios'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import InstantSearch from 'vue-instantsearch'
import Notifications from 'vue-notification'
import VueApp from './App.vue'
import eventEmitter from './event-emitter'
import router from './router'
import store from './store'
import mixins from './mixins.js'
import LazyLoadDirective from './directives/LazyLoadDirective'
import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing' payant

import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/style.css'

const IS_PRODUCTION = process.env.NODE_ENV === 'production'
if (IS_PRODUCTION) {
  Sentry.init({
    Vue,
    dsn: 'https://87bc99ed8edf4197af1bbf0621ac642d@o20614.ingest.sentry.io/5550159',
    environment: process.env.NODE_ENV,
    autoSessionTracking: true,
    integrations: [
      // new Integrations.BrowserTracing() payant
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  })
}

Vue.config.productionTip = false
Vue.prototype.$http = Axios
Vue.mixin(mixins)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(InstantSearch)
Vue.use(Notifications)
Vue.directive('lazyload', LazyLoadDirective)

const _sentrySetUser = () => {
  if (!IS_PRODUCTION) return

  if (store.isLogged()) {
    Sentry.setUser({ email: store.getUser().email })
  }
}
store.checkToken()
eventEmitter.on('auth:ckecked', () => {
  _sentrySetUser()
  new Vue({
    router,
    render: h => h(VueApp)
  }).$mount('#appWrap')
})
eventEmitter.on('auth:success', () => {
  _sentrySetUser()
})
