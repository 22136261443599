'use strict'

const _devices = [
  { text: 'App iOS', value: 'ios', icon: 'fab fa-apple' },
  { text: 'App Android', value: 'android', icon: 'fab fa-android' },
  { text: 'Web', value: 'web', icon: 'fas fa-desktop' }
]

const _links = [
  {
    code: 'commande-rapide',
    name: 'Commande rapide',
    thumb: 'https://www.labellevie.com/statics/images/cart-spide.png',
    sites: [1, 10, 8, 9, 11, 1013, 1014]
  },
  {
    code: 'recettes',
    name: 'Kits recettes',
    thumb: 'https://www.labellevie.com/statics/images/recipes/kitrecette.jpg',
    sites: [1]
  },
  {
    code: 'recettes-en-video',
    name: 'Kits recettes en video',
    thumb: 'https://www.labellevie.com/statics/images/recipes/kitrecette.jpg',
    sites: [1]
  },
  {
    code: 'recettes-personnelles',
    name: 'Recettes personnelles',
    thumb: 'https://www.labellevie.com/statics/images/recipes/kitrecette.jpg',
    sites: [1]
  },
  {
    code: 'home-service-and-categories',
    name: 'HOME - Services/Catégories',
    thumb: 'https://www.labellevie.com/statics/images/favicon.png',
    sites: [1, 1013]
  },
  {
    code: 'home-sections-banner',
    name: 'HOME - Bannière inter-sections',
    thumb: 'https://www.labellevie.com/statics/images/favicon.png',
    sites: [1]
  },
  {
    code: 'home-dlc-promo',
    name: 'HOME - Expire bientôt, prix réduits',
    thumb: 'https://www.labellevie.com/statics/images/favicon.png',
    sites: [1, 10, 8, 9, 11, 1013]
  },
  {
    code: 'home-kits-recettes',
    name: 'HOME - Les kits recettes',
    thumb: 'https://www.labellevie.com/statics/images/favicon.png',
    sites: [1]
  },
  {
    code: 'home-most-requested-products',
    name: 'HOME - Les produits les plus commandés',
    thumb: 'https://www.labellevie.com/statics/images/favicon.png',
    sites: [1]
  }
]

export default {
  getDevices: () => _devices,
  getDevice: (name) => _devices.find(o => o.value === name) || { value: '?', text: '?' },
  getLinkCategories: (siteId) => _links.filter(o => o.sites.indexOf(siteId) !== -1),
  getLinkCategory: (code) => _links.find(o => o.code === code)
}
