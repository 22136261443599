'use strict'

import { ApiDeleev } from '../index'

const _formatSites = (sites) => {
  sites = sites.filter(o => o.status > 0)
  let _indexed = {}
  sites.forEach((o, idx) => {
    _indexed[o.id] = idx
  })
  let sortedSites = sites.slice()
  sortedSites.sort((a, b) => {
    return (+b.is_default - +a.is_default) || a.name.localeCompare(b.name)
  })
  return {
    sites: sites,
    sites_indexed: _indexed,
    sortedSites: sortedSites
  }
}
export default {
  getSites () {
    return new Promise((resolve, reject) => {
      ApiDeleev.get('/sites/')
        .then(response => {
          if (response.status >= 400) return reject(response)
          resolve(_formatSites(response.data.results.filter(o => o.status > 0)))
        })
        .catch(reject)
    })
  },
  syncUberEatsMenu () {
    return new Promise((resolve, reject) => {
      ApiDeleev.post('/uber-eats/stores/sync-menu/')
        .then(response => {
          if (response.status >= 400) return reject(response)
          resolve(response.data)
        })
        .catch(reject)
    })
  }
}
