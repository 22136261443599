'use strict'

import { ApiDeleev } from '../index'

export default {
  login (user) {
    return new Promise((resolve, reject) => {
      ApiDeleev.post('/auth/login/', user)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  checkToken (token) {
    return new Promise((resolve, reject) => {
      if (!token) return reject(new Error('Token not found'))

      ApiDeleev.get('/admin-user/', { headers: { Authorization: 'Token ' + token } })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  logout () {
    return new Promise((resolve, reject) => {
      // do api logout action
      resolve()
    })
  }
}
