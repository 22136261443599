'use strict'

import axios from 'axios'

const _getDeleevBaseUrl = () => {
  return ['production', 'preprod'].indexOf(process.env.NODE_ENV) !== -1
    ? process.env.VUE_APP_DELEEV_API_BASE_URL
    : `/deleev-api`
}

const _getLabellevieBaseUrl = () => {
  return ['production', 'preprod'].indexOf(process.env.NODE_ENV) !== -1
    ? process.env.VUE_APP_LABELLEVIE_API_BASE_URL
    : '/labellevie-api'
}

const _getProxySearchBaseUrl = () => {
  return ['production', 'preprod'].indexOf(process.env.NODE_ENV) !== -1
    ? process.env.VUE_APP_PROXY_SEARCH_BASE_URL
    : '/proxysearch'
}

export const ApiDeleev = axios.create({
  baseURL: _getDeleevBaseUrl()
})

export const ApiLabellevie = axios.create({
  baseURL: _getLabellevieBaseUrl()
})

export const ApiProxysearch = axios.create({
  baseURL: _getProxySearchBaseUrl()
})

export const FrontLabellevie = axios.create({
  baseURL:
    ['production', 'preprod'].indexOf(process.env.NODE_ENV) !== -1
      ? process.env.VUE_APP_LABELLEVIE_FRONT_BASE_URL
      : '/labellevie-front'
})

export const ApiDeleevConfig = {
  setAuthorizationToken (token) {
    if (!token) return
    ApiDeleev.defaults.headers.common['Authorization'] = `Token ${token}`
    ApiLabellevie.defaults.headers.common['Authorization'] = `Token ${token}`
    ApiProxysearch.defaults.headers.common['Authorization'] = `Token ${token}`
  },
  removeAuthorizationToken () {
    delete ApiDeleev.defaults.headers.common['Authorization']
    delete ApiLabellevie.defaults.headers.common['Authorization']
    delete ApiProxysearch.defaults.headers.common['Authorization']
  },
  getHeadersWithByPassToken () {
    return Object.assign({}, ApiLabellevie.defaults.headers.common, {
      'x-api-bypass-token': process.env.VUE_APP_DELEEV_X_API_BYPASS_TOKEN
    })
  }
}

const _labellevieUploadBaseUrl = process.env.VUE_APP_LABELLEVIE_API_BASE_URL.replace('/1.0/api', '')
export const ApiLabellevieConfig = {
  getHeadersWithByPassToken () {
    return Object.assign({}, ApiLabellevie.defaults.headers.common, {
      'x-api-bypass-token': process.env.VUE_APP_DELEEV_X_API_BYPASS_TOKEN
    })
  },
  getUploadBaseUrl () {
    return _labellevieUploadBaseUrl
  }
}
