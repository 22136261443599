'use strict'

// async chunk: group-navigation
// const VueNavigation = () => import(/* webpackChunkName: "group-navigation" */ '../views/Navigation.vue')
// async chunk: group-category
const VueCategory = () => import(/* webpackChunkName: "group-category" */ '../views/Navigation/Category.vue')
const VueCategoryModal = () => import(/* webpackChunkName: "group-category" */ '../views/Navigation/CategoryModal.vue')
// async chunk: group-categories-products
const VueCategoriesProducts = () => import(/* webpackChunkName: "group-categories-products" */ '../views/Navigation/Categories')
// async chunk: group-sections
const VueSectionGroup = () => import(/* webpackChunkName: "group-sections" */ '../views/Navigation/SectionGroup.vue')
const VueSectionGroups = () => import(/* webpackChunkName: "group-sections" */ '../views/Navigation/SectionGroups.vue')
// async chunk: group-carousels
const VueCarousels = () => import(/* webpackChunkName: "group-carousels" */ '../views/Navigation/Carousels.vue')

export default [
  /*
  {
    path: '/navigation',
    name: 'navigation',
    component: VueNavigation,
    meta: { permission: 'category-manager' }
  },
  */
  {
    path: '/categories/:id',
    name: 'category',
    component: VueCategoryModal,
    beforeEnter: (to, from, next) => {
      if (['categories-products', 'section-group', 'carousels'].indexOf(from.name) !== -1) {
        to.matched[0].components.default = from.matched[0].components.default
        to.matched[0].components.modal = VueCategoryModal
      } else {
        to.matched[0].components.default = VueCategory
        delete to.matched[0].components.modal
      }
      next()
    },
    meta: { permission: 'category-manager' }
  },
  {
    path: '/categories-products',
    name: 'categories-products',
    component: VueCategoriesProducts,
    meta: { permission: 'category-manager' }
  },
  {
    path: '/section-groups',
    name: 'section-groups',
    component: VueSectionGroups,
    meta: { permission: 'category-manager' }
  },
  {
    path: '/section-group/:id?',
    name: 'section-group',
    component: VueSectionGroup,
    meta: { permission: 'category-manager' }
  },
  {
    path: '/carousels',
    name: 'carousels',
    component: VueCarousels,
    meta: { permission: 'category-manager' }
  }
]
