'use strict'

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store.js'

import ProductsRouter from './router/products'
import SuppliersRouter from './router/suppliers'
import LaboRouter from './router/labo'
import NavigationRouter from './router/navigation'
import SearchRouter from './router/search'
import RecipesRouter from './router/recipes'
import ReapproRouter from './router/reappro'
// import UberEatsRouter from './router/ubereats'

import VueLogin from './views/Login.vue'
import VueHome from './views/Home.vue'
import VueNotFound from './views/NotFound.vue'
import VueNotAuthorized from './views/NotAuthorized.vue'

// async chunk: group-centers
const VueCenters = () => import(/* webpackChunkName: "group-centers" */ './views/Centers.vue')
// async chunk: group-sites
const VueSites = () => import(/* webpackChunkName: "group-sites" */ './views/Sites.vue')
// async chunk: group-barcode
const VueBarcode = () => import(/* webpackChunkName: "group-barcode" */ './views/Barcode.vue')

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: VueLogin,
      name: 'login'
    },
    {
      path: '/autolog/:token',
      name: 'autolog'
    },
    {
      path: '/',
      name: 'home',
      component: VueHome
    },
    {
      path: '/centers',
      name: 'centers',
      component: VueCenters,
      meta: { permission: ['admin', 'support'] }
    },
    {
      path: '/sites',
      name: 'sites',
      component: VueSites,
      meta: { permission: ['admin', 'support'] }
    },
    {
      path: '/barcodes',
      name: 'barcodes',
      component: VueBarcode
    },
    ...ProductsRouter,
    ...SuppliersRouter,
    ...LaboRouter,
    ...NavigationRouter,
    ...SearchRouter,
    ...RecipesRouter,
    ...ReapproRouter,
    // ...UberEatsRouter,
    {
      path: '/not-authorized',
      name: '401',
      component: VueNotAuthorized
    },
    {
      path: '*',
      name: '404',
      component: VueNotFound
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.name === 'autolog') {
    if (to.params.token) {
      let _expireTime = new Date()
      _expireTime.setDate(_expireTime.getDate() + 1)
      Vue.$cookies.set(
        'api_token',
        to.params.token,
        _expireTime,
        '/',
        '.deleev.com'
      )
      // force reload page
      window.location.href = '/'
    } else {
      next({ path: '/' })
    }
    return
  }

  if (!store.isLogged() && to.name !== 'login') {
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else if (store.isLogged() && to.name === 'login') {
    next({ path: '/' })
  } else {
    if (to.meta && to.meta.permission) {
      if (to.meta.permission instanceof Array) {
        const userCan = to.meta.permission.some(p => store.userCan(p))
        userCan ? next() : next({ path: '/not-authorized' })
      } else {
        store.userCan(to.meta.permission) ? next() : next({ path: '/not-authorized' })
      }
    } else {
      next()
    }
  }
})

export default router
