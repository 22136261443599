'use strict'

const _validateAreaShelfTierIntegerRange = (area, shelf, tier) => {
  if (area < 0 || shelf < 0 || tier < 0) {
    return 'La zone, l\'étagère ou l\'étage ne peuvent pas être négatives.'
  }
  if (area > 9999 || shelf > 9999 || tier > 9999) {
    return 'La zone, l\'étagère ou l\'étage ne peuventt pas être supérieure à 9999.'
  }
  return true
}

const _validateAreaShelfTier = (area, shelf, tier) => {
  if (area === '' || shelf === '' || tier === '') {
    return 'La zone, l\'étagère ou l\'étage sont obligatoires.'
  }

  area = parseInt(area)
  shelf = parseInt(shelf)
  tier = parseInt(tier)
  if (isNaN(area) || isNaN(shelf) || isNaN(tier)) {
    return 'La zone, l\'étagère ou l\'étage doivent être un entier valide.'
  }
  if (area === 0 && shelf === 0 && tier === 0) {
    return 'Le zonage doit être différent de 0.0.0.'
  }
  return _validateAreaShelfTierIntegerRange(area, shelf, tier)
}

const _formatZone = (data) => {
  return [data.area, data.shelf, data.tier].join('.')
}

const _isSameZone = (source, zone) => {
  return +source.area === +zone.area && +source.shelf === +zone.shelf && +source.tier === +zone.tier
}

export default {
  validateAreaShelfTier: _validateAreaShelfTier,
  formatZone: _formatZone,
  isSameZone: _isSameZone
}
