'use strict'

const _number = (num, precision) => {
  precision = typeof precision === 'undefined' ? 2 : precision
  return Number((parseFloat(num) || 0).toFixed(precision)).toLocaleString('fr-FR', { minimumFractionDigits: precision })
}
const _formatted = (num, precision) => {
  let _postfix = ''
  num = parseFloat(num) || 0
  if (num > 1000000) {
    num = num / 1000000
    _postfix = 'M'
  } else if (num > 10000) {
    num = num / 1000
    _postfix = 'K'
  }
  return _number(num, precision || 0) + _postfix
}

const _currency = (num, precision) => {
  precision = typeof precision === 'undefined' ? 2 : precision
  return Number((parseFloat(num) || 0)
    .toFixed(precision))
    .toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: precision, maximumFractionDigits: precision })
}

const _percent = (num, precision) => {
  return _number(num, precision) + '%'
}

const _marge = (purchasePrice, sellingPrice, vat) => {
  return (sellingPrice * 100 / (100 + vat)) - purchasePrice
}

const _priceHT = (sellingPrice, vat) => {
  return sellingPrice / (1 + (vat / 100))
}

export default {
  number: _number,
  formatted: _formatted,
  currency: _currency,
  percent: _percent,
  marge: _marge,
  priceHT: _priceHT
}
