'use strict'

import { ApiDeleev } from '../index'

export default {
  getProductTypologies (params) {
    return new Promise((resolve, reject) => {
      ApiDeleev.get('/admin-products/product-typology/', { params: params })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  getProductTypologyById (id) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .get(`/admin-products/product-typology/${id}/`, {})
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  createProductTypology (data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .post('/admin-products/product-typology/', data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  updateProductTypology (id, data) {
    return new Promise((resolve, reject) => {
      ApiDeleev
        .patch(`/admin-products/product-typology/${id}/`, data)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  }
}
