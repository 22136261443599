'use strict'

import { ApiDeleev } from '../index'

export default {
  getFrichtiProductTypologies (params) {
    return new Promise((resolve, reject) => {
      ApiDeleev.get('/admin-products/product-frichti-typologies/', { params: params })
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  }
}
